import React from 'react';
import TechCard from '../../../../components/technologies-page/tech-card';
import MyPortfolioModal from '../experience-modals/portfolio-modal';
import ReactUdemyModal from '../experience-modals/react-udemy-modal';
import {TechnologyCardType} from '../../../../data-structures/components/technology-card.type';
import ApprentissagePaloItModal from '../experience-modals/apprentisage-palo-it-modal';

const ReactTechnology: TechnologyCardType = ({cardSize = 'large'}) => {
    const technologyName = 'React';

    return (
        <TechCard className="border-warning"
                  imageSource="img/technologies/react_logo.svg"
                  technologyName={technologyName}
                  noMarginBottom={true}
                  imageWidthInPercent={50}>

            <TechCard.Annexes>
                <small className="mb-0">Les technologies et modules :</small>
                <TechCard.Annexes.Element>
                    <TechCard.Annexes.Element.Technology
                        technologyParentName={technologyName}
                        technologyName="TypeScript">
                        Sur-ensemble syntaxique de JavaScript améliorant la structure et la sécurité du code.
                    </TechCard.Annexes.Element.Technology>
                </TechCard.Annexes.Element>
                <TechCard.Annexes.Element>
                    <TechCard.Annexes.Element.Technology
                        technologyParentName={technologyName}
                        technologyName="Stripe">
                        Solution de traitement de paiement en ligne.
                    </TechCard.Annexes.Element.Technology>
                </TechCard.Annexes.Element>
            </TechCard.Annexes>

            <TechCard.Gain>
                La bibliothèque React me permet de développer des applications Web monopage et de mieux structurer mes
                applications Web côté client.
            </TechCard.Gain>

            <TechCard.AddInfo technologyName={technologyName}>
                <TechCard.AddInfo.Affinity
                    technologyName={technologyName}
                    gradeValue={4}>
                    J'apprécie l’aspect structurant qui permet de réutilisabilité de composant développer et le fait de
                    pouvoir centraliser la gestion de l’interface côté client.
                </TechCard.AddInfo.Affinity>
                <TechCard.AddInfo.Experience
                    technologyName={technologyName}
                    gradeValue={2.5}>
                    Je nécessite de beaucoup plus d'expérience pour être plus performant et à l'aise, mais je pense à
                    l'utiliser pour de prochains projets.
                </TechCard.AddInfo.Experience>
            </TechCard.AddInfo>
            <TechCard.Experiences.Group>
                <TechCard.Experiences.Group.Element>
                    <TechCard.Experiences.Group.Element.Button.Modal
                        technologyName={technologyName}
                        ExperienceModal={ApprentissagePaloItModal} size='lg'/>
                </TechCard.Experiences.Group.Element>
                <TechCard.Experiences.Group.Element>
                    <TechCard.Experiences.Group.Element.Button.Modal
                        technologyName={technologyName}
                        ExperienceModal={MyPortfolioModal} size='lg'/>
                </TechCard.Experiences.Group.Element>
                <TechCard.Experiences.Group.Element>
                    <TechCard.Experiences.Group.Element.Button.UdemyModal
                        technologyName={technologyName}
                        ExperienceUdemyModal={ReactUdemyModal} size='lg'/>
                </TechCard.Experiences.Group.Element>
            </TechCard.Experiences.Group>
        </TechCard>
    );
};

export default ReactTechnology;