import React, {FunctionComponent} from 'react';
import {ExperienceModalInterface} from '../../../../data-structures/components/experience-modal.interface';
import Alinea from '../../../../components/alinea';

const MinecraftServerMillionVictoriesModalTitle: FunctionComponent = () => (
    <span>Serveur privé Minecraft</span>
);

const MinecraftServerMillionVictoriesModalContext: FunctionComponent = () => (
    <p>
        <Alinea/> <span className="fw-bold">Micro-projet personnel</span> fait en parallèle de mon <a
        href="https://youtu.be/XItV_9TzEac" target="_blank" rel="noopener noreferrer">stage à Million Victories</a>
    </p>
);

const MinecraftServerMillionVictoriesModalGoal: FunctionComponent = () => (
    <p>
        <Alinea/> Mettre en place un serveur de jeu Minecraft pour continuer mon intégration par le jeu dans l'équipe.
    </p>
);

const MinecraftServerMillionVictoriesModalResult: FunctionComponent = () => (
    <p>
        <Alinea/> <span className="fw-bold">Une configuration Terraform</span> déployant les services <span
        className='fw-bold'>Google Cloud Platform</span> nécessaires (réseau, pare-feu, machine virtuelle et
        disque de stockage).<br/>
        <Alinea/> L'installation et le lancement du serveur Minecraft avec des mods sur la machine virtuelle se font
        avec des <span className="fw-bold">scripts BASH</span>, des templates de configuration et <span
        className="fw-bold">Docker Compose</span>.
    </p>
);

const MinecraftServerMillionVictoriesModal: ExperienceModalInterface = {
    name: 'Serveur privé Minecraft',
    Title: MinecraftServerMillionVictoriesModalTitle,
    Context: MinecraftServerMillionVictoriesModalContext,
    Result: MinecraftServerMillionVictoriesModalResult,
    Goal: MinecraftServerMillionVictoriesModalGoal,

    info: {enjoyingNote: 4, learningNote: 2},
    git: {
        public: false
    },
    technologiesUsed: ['GCP', 'Terraform', 'Bash', 'Docker', 'Docker Compose']
};

export default MinecraftServerMillionVictoriesModal;