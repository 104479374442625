import {ExperienceUdemyModalInterface} from '../../../../data-structures/components/experience-udemy-modal.interface';
import DeveloppeurReactFormationCompleteUdemy from './experience-udemy/developpeur-react-formation-complete';

const ReactUdemyModal: ExperienceUdemyModalInterface = {
    name: 'Formation Udemy',
    udemyExperiences: [
        DeveloppeurReactFormationCompleteUdemy
    ]
};

export default ReactUdemyModal;