import React from 'react';
import TechCard from '../../../../components/technologies-page/tech-card';
import InternshipMillionVictories from '../experience-links/internship-million-victories';
import MinecraftServerMillionVictoriesModal from '../experience-modals/minecraft-server-million-victories-modal';
import {TechnologyCardType} from '../../../../data-structures/components/technology-card.type';
import StationProjectModal from '../experience-modals/station-project-modal';

const GoogleCloudPlatformTechnology: TechnologyCardType = ({cardSize = 'large'}) => {
    const technologyName = 'Google Cloud Platform';
    const technologyNameAcronym = 'GCP';
    return (
        <TechCard className="border-warning"
                  imageSource="img/technologies/GCP_logo.svg"
                  technologyName={technologyName} technologyNameAcronym={technologyNameAcronym}
                  imageWidthInPercent={50}>

            <TechCard.Annexes>
                <small className="mb-0">Les technologies :</small>
                <TechCard.Annexes.Element>
                    <TechCard.Annexes.Element.Technology
                        technologyParentName={technologyName}
                        technologyName="Terraform">
                        Outil d'Infrastructure As Code (IaC), il permet le déploiement d'infrastructures se basant sur
                        des fichiers de configuration.
                    </TechCard.Annexes.Element.Technology>
                </TechCard.Annexes.Element>
            </TechCard.Annexes>

            <TechCard.Gain>
                Google Cloud Platform me permet de déployer et héberger des applications dans le Cloud.<br/>
                Tout comme Amazone Web Services, il propose de nombreux services qui permettent de résoudre un large
                spectre de problématiques.<br/>
                Architecturalement il permet des infrastructures élastiques et tolérantes aux pannes.
            </TechCard.Gain>

            <TechCard.AddInfo technologyName={technologyName}>
                <TechCard.AddInfo.Affinity
                    technologyName={technologyName}
                    gradeValue={4.5}>
                    De nombreux services complets sont proposés et j'apprécie particulièrement l'expérience utilisateur
                    proposée par Google l’interface Web et l'organisation en projet.
                </TechCard.AddInfo.Affinity>
                <TechCard.AddInfo.Experience
                    technologyName={technologyName}
                    gradeValue={2.5}>
                    Je nécessite beaucoup plus d'expérience pour être plus performant et à l'aise,
                    mais je pense à l'utiliser pour de prochains projets.
                </TechCard.AddInfo.Experience>
            </TechCard.AddInfo>
            <TechCard.Experiences.Group>
                <TechCard.Experiences.Group.Element>
                    <TechCard.Experiences.Group.Element.Button.Modal
                        technologyName={technologyName}
                        ExperienceModal={StationProjectModal} size='lg'/>
                </TechCard.Experiences.Group.Element>
                <TechCard.Experiences.Group.Element>
                    <TechCard.Experiences.Group.Element.Button.Link
                        technologyName={technologyName}
                        experienceLinkData={InternshipMillionVictories}/>
                </TechCard.Experiences.Group.Element>
                <TechCard.Experiences.Group.Element>
                    <TechCard.Experiences.Group.Element.Button.Modal
                        technologyName={technologyName}
                        ExperienceModal={MinecraftServerMillionVictoriesModal} size='lg'/>
                </TechCard.Experiences.Group.Element>
            </TechCard.Experiences.Group>
        </TechCard>
    );
};

export default GoogleCloudPlatformTechnology;