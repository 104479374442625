import React, {FunctionComponent} from 'react';
import {ListGroup} from 'react-bootstrap';
import AnnexTechnologyElement from './annex-technology-element';

/**
 * Liste des technologies/modules/outils annexes a la technologie.
 * Le props "children" contiendra des composants ".Element" (ou "AnnexTechnologyElement"
 */
const TechnologyAnnexes: FunctionComponent = ({children}) => (
    <ListGroup>
        {children}
    </ListGroup>
);

export default Object.assign(TechnologyAnnexes, {
    Element: AnnexTechnologyElement
});