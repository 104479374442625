import React, {FunctionComponent} from 'react';
import {Button, OverlayTrigger, Popover} from 'react-bootstrap';
import {PopoverPlacementType} from '../../../../data-structures/types/popoverPlacement.type';

type Props = {
    technologyName: string
    technologyParentName: string
    blockButton?: boolean
    popoverPlacement?: PopoverPlacementType
}

/**
 * Bouton avec le nom de l'annexe et en popover une description courte
 * Le props "children" contiendra la description courte
 */
const AnnexTechnology: FunctionComponent<Props> = (
    {
        technologyParentName,
        technologyName,
        blockButton = true,
        popoverPlacement = 'auto',
        children
    }) => {

    const popover = (
        <Popover id={`${technologyParentName.toLowerCase()}-${technologyName.toLowerCase()}-popover`}>
            <Popover.Header as="h3">{technologyName}</Popover.Header>
            <Popover.Body>
                {children}
            </Popover.Body>
        </Popover>
    );

    if (blockButton)
        return (
            <div className={((blockButton) ? 'd-grid gap-2' : '')}>
                <OverlayTrigger trigger={['hover', 'focus']} placement={popoverPlacement} overlay={popover}>
                    <button className="btn btn-outline-info btn-sm"
                            data-track-component="AnnexTechnology"
                            data-track-technology={technologyName}
                            data-track-parent-technology={technologyParentName}
                            data-track-trigger="hover"
                    >
                        <span className="text-body fw-bold">{technologyName}</span>
                    </button>
                </OverlayTrigger>
            </div>
        );
    else
        return (
            <OverlayTrigger trigger={['hover', 'focus']} placement={popoverPlacement} overlay={popover}>
                <Button className="btn btn-outline-info btn-sm"
                        data-track-component="AnnexTechnology"
                        data-track-technology={technologyName}
                        data-track-parent-technology={technologyParentName}
                        data-track-trigger="hover"
                >{technologyName}</Button>
            </OverlayTrigger>
        );
};

export default AnnexTechnology;