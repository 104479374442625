import React, {FunctionComponent, useState} from 'react';
import {Button, Card, Collapse, ListGroup} from 'react-bootstrap';
import TechnologyAffinityGrade from './technology-grades/affinity-grade';
import TechnologyExperienceGrade from './technology-grades/experience-grade';

type Props = {
    /**
     * Only for tracking
     */
    technologyName: string
}

const AdditionalInformation: FunctionComponent<Props> = ({technologyName, children}) => {
    const [open, setOpen] = useState<boolean>(false);

    return (
        <Card.Body>
            <div className="d-grid gap-2">
                <Button
                    onClick={() => setOpen(!open)}
                    aria-expanded={open}
                    variant="outline-info"
                    size="sm"
                    data-track-component="AdditionalInformation"
                    data-track-technology={technologyName}
                >
                    <span className="text-body fw-bold">Information complémentaire</span>
                </Button>
                <Collapse in={open}>
                    <ListGroup>
                        {children}
                    </ListGroup>
                </Collapse>
            </div>
        </Card.Body>
    );
};

export default Object.assign(AdditionalInformation, {
    Affinity: TechnologyAffinityGrade,
    Experience: TechnologyExperienceGrade
});