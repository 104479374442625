import React, {FunctionComponent} from 'react';
import {ExperienceModalInterface} from '../../../../data-structures/components/experience-modal.interface';
import Alinea from '../../../../components/alinea';

const CamagruModalTitle: FunctionComponent = () => (
    <span>Camagru</span>
);

const CamagruModalContext: FunctionComponent = () => (
    <p>
        <Alinea/> Premier projet de la branche Web de
        l'école <a href="https://www.42lyon.fr/" target="_blank" rel="noopener noreferrer">42 Lyon</a>
    </p>
);

const CamagruModalGoal: FunctionComponent = () => (
    <p>
        <Alinea/> L'on doit réaliser, en PHP, un petit site Instagram-like permettant à des
        utilisateurs
        de
        réaliser et partager des photo-montages.<br/>
        L'on doit ainsi implémenter, à mains nues (les frameworks sont interdits), les
        fonctionnalités de base rencontrées sur la majorité des sites possédant une base
        utilisateur.<br/>
        Les détails du projet sont décrits dans le <a
        href="https://gitlab.com/charles.garoux/camagru/-/blob/master/docs/camagru.fr.pdf"
        target="_blank" rel="noopener noreferrer">sujet</a>.
    </p>
);

const CamagruModalResult: FunctionComponent = () => (
    <p>
        <Alinea/> Le projet a été <span className="fw-bold">validé par 3 correcteurs</span> durant la soutenance.
    </p>
);

const CamagruModal: ExperienceModalInterface = {
    name: 'Camagru',
    Title: CamagruModalTitle,
    Context: CamagruModalContext,
    Result: CamagruModalResult,
    Goal: CamagruModalGoal,

    info: {enjoyingNote: 3, learningNote: 3.5},
    git: {
        platform: 'Gitlab',
        link: 'https://gitlab.com/charles.garoux/camagru',
        public: true
    },
    technologiesUsed: ['HTML', 'CSS', 'Bootstrap', 'JavaScript', 'PHP', 'SQL', 'MariaDB', 'Docker', 'Docker Compose']
};

export default CamagruModal;