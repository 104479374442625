import React, {FunctionComponent} from 'react';
import {ExperienceUdemyInterface} from '../../../../../data-structures/components/experience-udemy-modal.interface';
import Alinea from '../../../../../components/alinea';

const LearnAmazonWebServicesUdemyPresentation: FunctionComponent = () => (
    <div>
        <p className="mt-3">
            <Alinea/>Cette formation est une introduction à AWS et au Cloud.<br/>
            <br/>
            <Alinea/>L’on y découvre différents modèles de service Cloud (IaaS, PaaS et SaaS). L’on nous y présente les
            aspects fonctionnels et des cas d'utilisations de service de base :
        </p>
        <ul>
            <li>S3</li>
            <li>EC2</li>
            <li>Lambda</li>
        </ul>
        <p className="mb-0">
            <Alinea/>Un Hands-on sur Lambda dont le but est de placer un bucket S3, la transformer par une fonction
            Lambda et la placer dans un autre bucket S3. D’autres Hands-on sont aussi proposés :
        </p>
        <ul>
            <li>Création d’instance EC2 Linux</li>
            <li>Création d’instance EC2 Windows Server</li>
            <li>Création d’instance EC2 avec un AMI communautaire (Wordpress)</li>
        </ul>
        <p>
            <Alinea/>Dans une section, la majorité des services AWS sont chacun présentés rapidement pour nous permettre
            d’entrevoir de ce qui est proposé et possible AWS.<br/>
            <Alinea/>En fin de formation l’on nous présente les différentes certifications AWS existantes, leurs
            intérêts et les niveaux de certifications.
        </p>
    </div>
);

const LearnAmazonWebServicesUdemy: ExperienceUdemyInterface = {
    name: 'Amazon Web Services (AWS): The complete introduction',
    certificatLink: 'https://www.udemy.com/certificate/UC-2b260933-cf4b-4507-8b7a-e7bd7a2a690e/',
    Presentation: LearnAmazonWebServicesUdemyPresentation
};

export default LearnAmazonWebServicesUdemy;