import React, {FunctionComponent} from 'react';
import EventCard from '../../../components/events-page/event-card';
import Alinea from '../../../components/alinea';

const HourOfCode2019Event: FunctionComponent = () => (
    <EventCard name="Hour of Code 2019" date="decembre 2019">
        <EventCard.Description>
            <Alinea/><a href="https://hourofcode.com/fr">Hour of Code</a> est une introduction d'une heure à
            l'informatique. Ce programme est conçu pour
            démystifier la programmation et montrer que n'importe qui peut en apprendre les rudiments du code.
        </EventCard.Description>
        <EventCard.Annexe.Modal name="Plus de details" size="lg">
            <p className="mb-0">
                <Alinea/><a href="https://hourofcode.com/fr">Hour of Code</a> est une introduction d'une heure à
                l'informatique. Ce programme est conçu pour démystifier la programmation et montrer que n'importe qui
                peut en apprendre les rudiments.<br/>
                <br/>
                <Alinea/>Avec d'autres étudiants de l'école <a href="https://www.42lyon.fr/" target="blank">42
                Lyon</a> nous
                avons fait découvrir la programmation à plusieurs classes de 6<sup>ème</sup>. Leur découverte c'est fait
                sur un mélange de Scratch et du jeu Minecraft.
                Avec <a href="https://studio.code.org/s/mc/reset" target="blank">ce jeu</a> ils ont peu découvrir
                les base de la programmation comme les boucles et les fonctions.<br/>
                <br/>
                Nos deux objectifs étaient :
            </p>
            <ul>
                <li>Montrer que la programmation est à la portée de tous.</li>
                <li>Montrer que la programmation n'est pas qu'un domaine pour les hommes, mais un
                    domaine qui est aussi fait pour les femmes.
                </li>
            </ul>
        </EventCard.Annexe.Modal>
    </EventCard>
);

export default HourOfCode2019Event;