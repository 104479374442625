import {FunctionComponent} from 'react';
import Navbar from '../../components/navbar';

const PageTemplate: FunctionComponent = ({children}) => {
    return (
        <div>
            <Navbar/>
            <div className="container-fluid mt-2">
                <div className="row mt-3">
                    {children}
                </div>
            </div>
        </div>
    );
};

export default PageTemplate;