import React, {FunctionComponent} from 'react';
import {ExperienceModalInterface} from '../../../../data-structures/components/experience-modal.interface';
import Alinea from '../../../../components/alinea';

const HypertubeModalTitle: FunctionComponent = () => (
    <span>Hypertube</span>
);

const HypertubeModalContext: FunctionComponent = () => (
    <p>
        <Alinea/> Dernier projet de la branche Web avant le premier stage de l'école <a
        href="https://www.42lyon.fr/" target="blank">42 Lyon</a>, il doit être fait en
        équipe de
        trois à quatre étudiants.<br/>
        Je suis arrivé en cours de route avec un camarade sur le projet de deux autres
        étudiants
        et
        je découvrais cette technologie et ces nouveaux paradigme de programmation.
    </p>
);

const HypertubeModalGoal: FunctionComponent = () => (
    <p>
        <Alinea/> Dernier projet de sa série, le projet Hypertube nous invite à découvrir une
        catégorie
        d'outil extrêmement puissante : les frameworks MVC.<br/>
        Nous apprendrons à manipuler un MVC, dans le langage de notre choix, pour réaliser
        un
        site
        de streaming de video téléchargées via le protocole BitTorrent.
    </p>
);

const HypertubeModalResult: FunctionComponent = () => (
    <p>
        <Alinea/> D’abord, je me suis concentré sur l’organisation de l'équipe notamment grâce à un
        schéma
        de
        l’architecture de l’application et l’utilisation de Zenkit, un outil de gestion de
        projet.<br/>
        <Alinea/> Ensuite, j’ai développé un système de cache de données pour les films et séries avec
        les
        torrents les plus populaires.<br/>
        <Alinea/> Pour finir, j’ai travaillé avec un équipier plus expérimenté sur la technologie pour
        refactoriser une majeure partie du projet pour éviter une dette technique.<br/>
        <br/>
        <Alinea/> Le projet a été <span className="fw-bold">validé par 5 correcteurs</span> durant la soutenance avec
        mon équipe.<br/>
    </p>
);

const HypertubeModal: ExperienceModalInterface = {
    name: 'Hypertube',
    Title: HypertubeModalTitle,
    Context: HypertubeModalContext,
    Result: HypertubeModalResult,
    Goal: HypertubeModalGoal,

    info: {enjoyingNote: 5, learningNote: 5},
    git: {
        platform: 'Gitlab',
        link: 'https://gitlab.com/charles.garoux/hypertube',
        public: true
    },
    technologiesUsed: ['HTML', 'CSS', 'JavaScript', 'Node.js', 'Express.js', 'EJS', 'MongoDB']
};

export default HypertubeModal;