import React from 'react';
import TechCard from '../../../../components/technologies-page/tech-card';
import InternshipMillionVictories from '../experience-links/internship-million-victories';
import {TechnologyCardType} from '../../../../data-structures/components/technology-card.type';
import InternshipMegaCrea from '../experience-links/internship-megacrea';
import CocadminGitlabCI from '../experience-links/cocadmin-gitlab-ci';
import MyPortfolioModal from '../experience-modals/portfolio-modal';
import ApprentissagePaloItModal from '../experience-modals/apprentisage-palo-it-modal';

const GitlabCiTechnology: TechnologyCardType = ({cardSize = 'large'}) => {
    const technologyName = 'Gitlab CI';

    return (
        <TechCard className="border-warning"
                  imageSource="img/technologies/gitlab_logo.svg"
                  technologyName={technologyName}
                  imageWidthInPercent={40}
                  noMarginBottom={true}>

            <TechCard.Annexes>
                <small className="mb-0">Les technologies et outils :</small>
                <TechCard.Annexes.Element>
                    <TechCard.Annexes.Element.Technology
                        technologyParentName={technologyName}
                        technologyName="Docker">
                        Outil de conteneurisation qui permet d’empaqueter une application et ses dépendances dans un
                        conteneur isolé.
                    </TechCard.Annexes.Element.Technology>
                </TechCard.Annexes.Element>
                <TechCard.Annexes.Element>
                    <TechCard.Annexes.Element.Technology
                        technologyParentName={technologyName}
                        technologyName="AWS S3">
                        S3 est un service de stockage de fichiers proposé par AWS.<br/>
                        Il peut servir à héberger un site web statique.
                    </TechCard.Annexes.Element.Technology>
                </TechCard.Annexes.Element>
            </TechCard.Annexes>

            <TechCard.Gain>
                Gitlab CI me permet d’automatiser des tâches de projet comme les constructions de livrable ou de
                déploiement de livrable, par rapport à mon dépôt Git sur Gitlab.
            </TechCard.Gain>

            <TechCard.AddInfo technologyName={technologyName}>
                <TechCard.AddInfo.Affinity
                    technologyName={technologyName}
                    gradeValue={3.5}>
                    J'apprécie l’automatisation de tâches apportées, mais je ne suis pas encore à l’aise avec l’outil et
                    le développement de configuration de pipeline.
                </TechCard.AddInfo.Affinity>
                <TechCard.AddInfo.Experience
                    technologyName={technologyName}
                    gradeValue={2.5}>
                    Je nécessite plus d'expérience pour être plus performant et à l'aise, mais je l’utilise le plus
                    possible dans mes projets.
                </TechCard.AddInfo.Experience>
            </TechCard.AddInfo>
            <TechCard.Experiences.Group>
                <TechCard.Experiences.Group.Element>
                    <TechCard.Experiences.Group.Element.Button.Modal
                        technologyName={technologyName}
                        ExperienceModal={ApprentissagePaloItModal} size='lg'/>
                </TechCard.Experiences.Group.Element>
                <TechCard.Experiences.Group.Element>
                    <TechCard.Experiences.Group.Element.Button.Modal
                        technologyName={technologyName}
                        ExperienceModal={MyPortfolioModal} size='lg'/>
                </TechCard.Experiences.Group.Element>
                <TechCard.Experiences.Group.Element>
                    <TechCard.Experiences.Group.Element.Button.Link
                        technologyName={technologyName}
                        experienceLinkData={InternshipMegaCrea}/>
                </TechCard.Experiences.Group.Element>
                <TechCard.Experiences.Group.Element>
                    <TechCard.Experiences.Group.Element.Button.Link
                        technologyName={technologyName}
                        experienceLinkData={CocadminGitlabCI}/>
                </TechCard.Experiences.Group.Element>
                <TechCard.Experiences.Group.Element>
                    <TechCard.Experiences.Group.Element.Button.Link
                        technologyName={technologyName}
                        experienceLinkData={InternshipMillionVictories}/>
                </TechCard.Experiences.Group.Element>
            </TechCard.Experiences.Group>
        </TechCard>
    );
};

export default GitlabCiTechnology;