import {ExperienceUdemyModalInterface} from '../../../../data-structures/components/experience-udemy-modal.interface';
import AwsConceptsUdemy from './experience-udemy/aws-concepts-udemy';
import LearnAmazonWebServicesUdemy from './experience-udemy/learn-amazon-web-services-udemy';
import AwsEssentialsUdemy from './experience-udemy/aws-essentials-udemy';
import PracticalBeginnersCourseToServerlessApplicationsUdemy
    from './experience-udemy/praticle-beginner-course-to-serverless-applications-udemy';

const AmazonWebServicesUdemyModal: ExperienceUdemyModalInterface = {
    name: 'Formation Udemy',
    udemyExperiences: [
        AwsConceptsUdemy,
        LearnAmazonWebServicesUdemy,
        AwsEssentialsUdemy,
        PracticalBeginnersCourseToServerlessApplicationsUdemy
    ]
};

export default AmazonWebServicesUdemyModal;