import React, {FunctionComponent} from 'react';
import {Link} from 'react-router-dom';
import {ExperienceLinkInterface} from '../../../../../data-structures/components/experience-link.interface';

type Props = {
    experienceLinkData: ExperienceLinkInterface
    /**
     * Only for tracking
     */
    technologyName: string
}

const htmlIcons = {
    'youtube-video': '<img src="/svg/youtube.svg"/>',
    'web-site': '' // Todo : ajouter un logo pour indiquer que c'est un site web
};

const ExperienceLinkButton: FunctionComponent<Props> = ({experienceLinkData, technologyName}) => {
    let linkData;
    if (experienceLinkData.isExternalLink)
        linkData = {pathname: experienceLinkData.link};
    else
        linkData = experienceLinkData.link;

    return (
        <div className="d-grid gap-2">
            <Link className="text-decoration-none btn bg-special-blue text-light my-auto" to={linkData} target="_blank"
                  rel="noopener noreferrer"
                  data-track-component="ExperienceLinkButton"
                  data-track-experience={experienceLinkData.name}
                  data-track-technology={technologyName}
            >
                {experienceLinkData.type &&
                <span className='me-2' dangerouslySetInnerHTML={{__html: htmlIcons[experienceLinkData.type]}}/>
                }
                {experienceLinkData.name}
            </Link>
        </div>
    );
};

export default ExperienceLinkButton;