import React, {FunctionComponent} from 'react';
import Routes from './Routes';

const App: FunctionComponent = () => {
    return (
        <div>
            <Routes/>
        </div>
    );
};

export default App;
