import React, {FunctionComponent, useState} from 'react';
import {ExperienceUdemyModalInterface} from '../../../../../data-structures/components/experience-udemy-modal.interface';
import ExperienceDisplayUdemyModal from '../displays/experience-display-udemy-modal';

type Props = {
    ExperienceUdemyModal: ExperienceUdemyModalInterface
    size?: 'sm' | 'lg' | 'xl';
    /**
     * Only for tracking
     */
    technologyName: string
}

const ExperienceUdemyModalButton: FunctionComponent<Props> = ({ExperienceUdemyModal, size, technologyName}) => {
    const [show, setShow] = useState(false);

    // const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <div className="d-grid gap-2">
            <button type="button" className="btn btn-udemy" onClick={handleShow}
                    data-track-component="ExperienceUdemyModalButton"
                    data-track-experience={ExperienceUdemyModal.name}
                    data-track-technology={technologyName}
            >
                {ExperienceUdemyModal.name}
            </button>
            <ExperienceDisplayUdemyModal show={show} setShow={setShow} size={size}
                                         UdemyExperienceModal={ExperienceUdemyModal}/>
        </div>
    );
};

export default ExperienceUdemyModalButton;