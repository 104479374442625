import React, {FunctionComponent} from 'react';
import {ExperienceModalInterface} from '../../../../data-structures/components/experience-modal.interface';
import Alinea from '../../../../components/alinea';

const Internship303EventModalTitle: FunctionComponent = () => (
    <span>303Event</span>
);

const Internship303EventModalContext: FunctionComponent = () => (
    <p>
        <Alinea/> Ce projet a été ce que j'ai développé pour mon <span className="fw-bold">stage de première année de BTS SIO</span>, dans l'association
        "303 airsoft team".<br/>
        <Alinea/> Au cours d'un entretien avec mon maître de stage j'ai pu connaître les besoins de
        l'association. J'ai produit l'analyse pour faire un schéma entité/association afin de développer l'application
        Web demandé par l'association.<br/>
        <Alinea/> Ce projet a été <span className="fw-bold">développé en télétravail</span> depuis chez moi ce qui m'a
        permis d'avoir un environnement de travail
        familier.
    </p>
);

const Internship303EventModalGoal: FunctionComponent = () => (
    <p>
        <Alinea/> Permettre a l'association de gérer leurs événements ainsi que les informations sur les participants
        par un outil unique. Leurs outils étant Facebook et un tableur.
    </p>
);

const Internship303EventModalResult: FunctionComponent = () => (
    <p>
        <Alinea/> L'application a été développée avec les frameworks Laravel et Boostrap mais l'application n'a pas pu
        être mise en production, car elle nécessitait des démarches administratives auprès de la CNIL.
    </p>
);

const Internship303EventModal: ExperienceModalInterface = {
    name: '303Event',
    Title: Internship303EventModalTitle,
    Context: Internship303EventModalContext,
    Result: Internship303EventModalResult,
    Goal: Internship303EventModalGoal,

    info: {enjoyingNote: 5, learningNote: 4},
    git: {
        public: false
    },
    technologiesUsed: ['HTML', 'CSS', 'Bootstrap', 'PHP', 'Laravel', 'SQL', 'MariaDB']
};

export default Internship303EventModal;