import React, {FunctionComponent, useState,} from 'react';
import {Button, Modal} from 'react-bootstrap';

type Props = {
    name: string
    title?: string
    size?: 'sm' | 'lg'
}

/**
 * le prop "children" est le contenue du "body" du modal
 */
const EventCardModal: FunctionComponent<Props> = ({name, title, size = 'sm', children}) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <div>
            <div className="d-grid gap-2 mt-3">
                <Button variant="success" onClick={handleShow}>
                    {name}
                </Button>
            </div>

            <Modal show={show} onHide={handleClose} size={size}>
                <Modal.Header closeButton>
                    <Modal.Title>{title ? title : name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {children}
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default EventCardModal;