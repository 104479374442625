import React, {FunctionComponent, useState} from 'react';
import NavBarButton from './navbar-button';
import {Collapse} from 'react-bootstrap';

const Navbar: FunctionComponent = () => {
    const [open, setOpen] = useState(false);

    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-special-blue sticky-top">
            <div className="container-fluid">
                <h1 className="navbar-brand text-light my-auto">Charles Garoux</h1>
                <button
                    onClick={() => setOpen(!open)}
                    aria-controls="example-collapse-text"
                    aria-expanded={open}
                    className="navbar-toggler bg-special-blue-light text-light" type="button" data-bs-toggle="collapse"
                    data-bs-target="#navbarNav"
                    // aria-controls="navbarNav"
                    // aria-expanded="false"
                    aria-label="Toggle navigation">
                    <i className="bi bi-list"/>
                </button>
                {/*Todo :  patch le probleme de collapse (le collapse de base ne fonctionne pas)*/}
                <Collapse in={open}>
                    <div className={`collapse navbar-collapse ${(open) ? '' : 'visually-hidden'}`}>
                        <ul className="navbar-nav">
                            <NavBarButton name={'Présentation'} route={'/'}/>
                            <NavBarButton name={'Technologies'} route={'/technologies'}/>
                            <NavBarButton name={'Événements '} route={'/evenements'}/>
                        </ul>
                    </div>
                </Collapse>
                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav">
                        <NavBarButton name={'Présentation'} route={'/'}/>
                        <NavBarButton name={'Technologies'} route={'/technologies'}/>
                        <NavBarButton name={'Événements '} route={'/evenements'}/>
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;