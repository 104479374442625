import {FunctionComponent} from 'react';
import VisitCardLink from './visit-card-link';

const VisitCard: FunctionComponent = () => {
    return (
        <div className="col-xl-3 col-lg-4 col-sm-6 col-12 my-md-auto mt-0">
            <div className="card">
                <img className="card-img-top" src="img/photo/image.png" alt="Charles Garoux"/>
                <div className="card-body px-0 py-0">
                    <ul className="list-group list-group-flush">
                        <VisitCardLink isExternalLink={true} link={'https://www.linkedin.com/in/charles-garoux'}>
                            LinkedIn
                        </VisitCardLink>
                        <VisitCardLink link={'/document/CV.pdf'}>
                            CV
                        </VisitCardLink>
                        <VisitCardLink isExternalLink={true} link={'https://gitlab.com/charles.garoux'}>
                            Gitlab
                        </VisitCardLink>
                        <VisitCardLink isExternalLink={true} link={'mailto:garoux.charles+pro@gmail.com'}>
                            garoux.charles+pro@gmail.com
                        </VisitCardLink>
                        <VisitCardLink isExternalLink={true} link={'https://www.google.fr/maps/place/69110+Sainte-Foy-l%C3%A8s-Lyon/@45.7364832,4.7888876,14.5z/data=!4m5!3m4!1s0x47f4ebfab481e01d:0x1c08ab2e41e477e0!8m2!3d45.7322183!4d4.7973799'}>
                            Lyon <small>(69110 Sainte-Foy-lès-Lyon)</small>
                        </VisitCardLink>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default VisitCard;