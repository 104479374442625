import React from 'react';
import TechCard from '../../../../components/technologies-page/tech-card';
import InternshipMillionVictories from '../experience-links/internship-million-victories';
import MinecraftServerMillionVictoriesModal from '../experience-modals/minecraft-server-million-victories-modal';
import Cloud1Modal from '../experience-modals/cloud-1-modal';
import {TechnologyCardType} from '../../../../data-structures/components/technology-card.type';
import StationProjectModal from '../experience-modals/station-project-modal';
import ApprentissagePaloItModal from '../experience-modals/apprentisage-palo-it-modal';

const TerraformTechnology: TechnologyCardType = ({cardSize = 'large'}) => {
    const technologyName = 'Terraform';

    return (
        <TechCard className="border-warning"
                  imageSource="img/technologies/terraform_logo.png"
                  technologyName={technologyName}
                  imageWidthInPercent={40}
                  noMarginBottom={true}>

            <TechCard.Annexes>
                <small className="mb-0">Les <span
                    className="text-decoration-underline-dotted"
                    title="plugins pour interagir avec des API de fournisseur Cloud par exemple"
                >providers<sup>?</sup></span> Terraform :</small>
                <TechCard.Annexes.Element>
                    <TechCard.Annexes.Element.Technology
                        technologyParentName={technologyName}
                        technologyName="AWS">
                        Un fournisseur Cloud proposé par une division du groupe Amazon.
                    </TechCard.Annexes.Element.Technology>
                </TechCard.Annexes.Element>
                <TechCard.Annexes.Element>
                    <TechCard.Annexes.Element.Technology
                        technologyParentName={technologyName}
                        technologyName="GCP">
                        Un fournisseur Cloud proposé par Google.
                    </TechCard.Annexes.Element.Technology>
                </TechCard.Annexes.Element>
                <TechCard.Annexes.Element>
                    <TechCard.Annexes.Element.Technology
                        technologyParentName={technologyName}
                        technologyName="Kubernetes">
                        Plateforme d’automatisation de déploiement, de montée en charge et de mise en œuvre de
                        conteneurs d'application sur des clusters de serveur.
                    </TechCard.Annexes.Element.Technology>
                </TechCard.Annexes.Element>
                <TechCard.Annexes.Element>
                    <TechCard.Annexes.Element.Technology
                        technologyParentName={technologyName}
                        technologyName="Gitlab">
                        Logiciel libre de forge logiciel basé sur git.
                    </TechCard.Annexes.Element.Technology>
                </TechCard.Annexes.Element>
            </TechCard.Annexes>

            <TechCard.Gain>
                Cette technologie me permet de développer, déployer et détruire principalement des infrastructures dans
                le Cloud à partir de configuration basé sur du code.
            </TechCard.Gain>

            <TechCard.AddInfo technologyName={technologyName}>
                <TechCard.AddInfo.Affinity
                    technologyName={technologyName}
                    gradeValue={4.5}>
                    J'apprécie la polyvalence par la richesse des fournisseurs supportés ainsi que le langage utilisé
                    pour le développement qui est très confortable par rapport à d'autres technologies d’IaC.
                </TechCard.AddInfo.Affinity>
                <TechCard.AddInfo.Experience
                    technologyName={technologyName}
                    gradeValue={3}>
                    Je nécessite plus d'expérience pour être plus performant, mais je pense à l'utiliser pour de
                    prochains projets.
                </TechCard.AddInfo.Experience>
            </TechCard.AddInfo>
            <TechCard.Experiences.Group>
                <TechCard.Experiences.Group.Element>
                    <TechCard.Experiences.Group.Element.Button.Modal
                        technologyName={technologyName}
                        ExperienceModal={ApprentissagePaloItModal} size='lg'/>
                </TechCard.Experiences.Group.Element>
                <TechCard.Experiences.Group.Element>
                    <TechCard.Experiences.Group.Element.Button.Modal
                        technologyName={technologyName}
                        ExperienceModal={StationProjectModal} size='lg'/>
                </TechCard.Experiences.Group.Element>
                <TechCard.Experiences.Group.Element>
                    <TechCard.Experiences.Group.Element.Button.Modal
                        technologyName={technologyName}
                        ExperienceModal={Cloud1Modal} size='lg'/>
                </TechCard.Experiences.Group.Element>
                <TechCard.Experiences.Group.Element>
                    <TechCard.Experiences.Group.Element.Button.Link
                        technologyName={technologyName}
                        experienceLinkData={InternshipMillionVictories}/>
                </TechCard.Experiences.Group.Element>
                <TechCard.Experiences.Group.Element>
                    <TechCard.Experiences.Group.Element.Button.Modal
                        technologyName={technologyName}
                        ExperienceModal={MinecraftServerMillionVictoriesModal} size='lg'/>
                </TechCard.Experiences.Group.Element>
            </TechCard.Experiences.Group>
        </TechCard>
    );
};

export default TerraformTechnology;