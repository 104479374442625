import React, {FunctionComponent} from 'react';

const Interets: FunctionComponent = () => (
    <div>
        <div className="row justify-content-center">
            <h3 className="mt-4 text-center">Événements qui m'intéresses</h3>
        </div>
        <div className="row justify-content-center mt-3">
            <div className="col-xxl-6 col-lg-9 col-md-9 col-12">
                <div className="border rounded-3 shadow py-3 px-3">
                    <span className="h5 font-weight-bold">Les sujets :</span>
                    <div className="row">
                        <div className="col-md-6 col-12 my-0">
                            <ul className="my-0">
                                <li>Cloud (AWS & GCP)</li>
                                <li>Gestion d'infrastructure</li>
                                <li>Développement Web</li>
                            </ul>
                        </div>
                        <div className="col-md-6 col-12 my-0">
                            <ul className="my-0">
                                <li>Data Science</li>
                                <li>Méthode de travail (Agile, ...)</li>
                                <li>Serious Game</li>
                            </ul>
                        </div>
                        <div className="col-md-6 col-12 my-0">
                            <ul className="my-0">
                                <li>SEO</li>
                                <li>Entreprenariat</li>
                                {/*<li></li>*/}
                            </ul>
                        </div>
                        <div className="col-md-6 col-12 my-0">
                            <ul className="my-0">
                                <li>Freelance</li>
                                {/*<li></li>*/}
                                {/*<li></li>*/}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
);


export default Interets;