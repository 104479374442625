import React, {FunctionComponent} from 'react';
import {ExperienceModalInterface} from '../../../../data-structures/components/experience-modal.interface';
import Alinea from '../../../../components/alinea';

const Cloud1ModalTitle: FunctionComponent = () => (
    <span>cloud-1</span>
);

const Cloud1ModalContext: FunctionComponent = () => (
    <p>
        <Alinea/> Projet de la branche Cloud de
        l'école <a href="https://www.42lyon.fr/" target="_blank" rel="noopener noreferrer">42 Lyon</a>
    </p>
);

const Cloud1ModalGoal: FunctionComponent = () => (
    <p>
        <Alinea/> Déployer une infrastructure Cloud permettant le fonctionnement d’une application Wordpress
        distribuée. <br/>
        Le sujet n’impose pas de façon de procéder. Le plus souvent, l'infrastructure est déployée via la console Web du
        fournisseur.
    </p>
);

const Cloud1ModalResult: FunctionComponent = () => (
    <p>
        <Alinea/> Une configuration Terraform déployant les services Amazon Web Services (AWS) permettant le
        fonctionnement d’une application Wordpress distribuée.<br/>
        <br/>
        Les services AWS utilisées :
        <ul className="list-inline">
            <li className=" list-inline-item badge rounded-pill bg-warning text-dark">VPC</li>
            <li className=" list-inline-item badge rounded-pill bg-warning text-dark">EC2</li>
            <li className=" list-inline-item badge rounded-pill bg-warning text-dark">EFS</li>
            <li className=" list-inline-item badge rounded-pill bg-warning text-dark">System Manager</li>
            <li className=" list-inline-item badge rounded-pill bg-warning text-dark">RDS</li>
            <li className=" list-inline-item badge rounded-pill bg-warning text-dark">S3</li>
            <li className=" list-inline-item badge rounded-pill bg-warning text-dark">CloudFront</li>
        </ul>
        Le projet a été <span className="fw-bold">validé par 5 correcteurs</span> durant la soutenance.

    </p>
);

const Cloud1Modal: ExperienceModalInterface = {
    name: 'cloud-1',
    Title: Cloud1ModalTitle,
    Context: Cloud1ModalContext,
    Result: Cloud1ModalResult,
    Goal: Cloud1ModalGoal,

    info: {enjoyingNote: 3.5, learningNote: 4.5},
    git: {
        platform: 'Gitlab',
        link: 'https://gitlab.com/charles.garoux/42-cloud-1',
        public: true
    },
    technologiesUsed: ['AWS', 'Terraform', 'Bash']
};

export default Cloud1Modal;