import React, {FunctionComponent} from 'react';
import {Button, ListGroup, OverlayTrigger, Popover} from 'react-bootstrap';
import Grade, {SymboleType} from '../../../../grade';
import {PopoverPlacementType} from '../../../../../data-structures/types/popoverPlacement.type';

type TechnologyBaseGradeProps = {
    technologyName: string
    blockButton?: boolean
    popoverPlacement?: PopoverPlacementType
    colorTheme?: string
    gradeValue: number
    gradeMax?: number
    buttonSize?: 'sm' | 'lg'
    titlePrefix: string
    // Grade type in lowercase. Example : "affinity"
    gradeType: string
    gradeSymbole: SymboleType
}

/**
 * Base de bouton de notation: Bouton avec la note et en popover le texte.
 * Le props "children" contiendra le texte
 */
const TechnologyBaseGrade: FunctionComponent<TechnologyBaseGradeProps> = (
    {
        technologyName,
        blockButton = true,
        popoverPlacement = 'auto',
        colorTheme = 'info',
        buttonSize = undefined,
        gradeValue,
        gradeMax = 5,
        gradeSymbole,
        gradeType,
        titlePrefix,
        children
    }) => {
    const title = `${titlePrefix} ${technologyName}`

    const popover = (
        <Popover id={`${technologyName.toLowerCase()}-${gradeType}-popover`}>
            <Popover.Header as="h3">{title}</Popover.Header>
            <Popover.Body>
                {children}
            </Popover.Body>
        </Popover>
    );

    const button = (
        <Button variant={colorTheme} size={buttonSize}
                data-track-component="TechnologyBaseGrade"
                data-track-grade-title={title}
                data-track-technology={technologyName}
                data-track-trigger="hover"
        >
            <Grade iconClassName="mx-1" value={gradeValue} max={gradeMax} symbole={gradeSymbole}/>
        </Button>
    );

    if (blockButton)
        return (
            <ListGroup.Item className={((blockButton) ? 'd-grid gap-2' : '')}>
                <OverlayTrigger trigger={['hover', 'focus']} placement={popoverPlacement} overlay={popover}>
                    {button}
                </OverlayTrigger>
            </ListGroup.Item>
        );
    else
        return (
            <OverlayTrigger trigger={['hover', 'focus']} placement={popoverPlacement} overlay={popover}>
                {button}
            </OverlayTrigger>
        );
};


export default TechnologyBaseGrade;