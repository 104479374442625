import React, {FunctionComponent} from 'react';
import PageTemplate from '../../components/page-template';
import TechnologicalNews from './technological-news';
import FullStackProfil from './full-stack-profil';
import TechnologyPart from './parts';

const TechnologiesPage: FunctionComponent = () => {
    return (
        <PageTemplate>
            <TechnologicalNews/>
            <FullStackProfil/>
            <TechnologyPart/>
        </PageTemplate>
    );
};

export default TechnologiesPage;