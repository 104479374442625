import React, {FunctionComponent, useState} from 'react';
import {Button, Collapse as BootstrapCollapse} from 'react-bootstrap';

type Props = {
    variant?: string
    className?: string
    buttonText: string
    blockButton?: boolean
}

const Collapse: FunctionComponent<Props> = ({variant = 'primary', className = '', buttonText, blockButton = false, children}) => {
    const [open, setOpen] = useState(false);

    return (
        <div className={((blockButton) ? 'd-grid gap-2' : '')}>
            <Button className={className} variant={variant}
                    onClick={() => setOpen(!open)}
                    aria-expanded={open}
            >
                {buttonText}
            </Button>
            <BootstrapCollapse in={open}>
                <div>
                    {children}
                </div>
            </BootstrapCollapse>
        </div>
    );
};

export default Collapse;