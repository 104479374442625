import React, {FunctionComponent} from 'react';
import EventCardLink from './event-card-link';
import EventCardModal from './event-card-modal';

const EventCardDescription: FunctionComponent = ({children}) => {
    return (
        <p>
            {children}
        </p>
    );
};

type EventCardProps = {
    name: string
    date: string
    place?: string
}

const EventCard: FunctionComponent<EventCardProps> = ({name, date, place, children}) => {
    const metadataClassName = (place) ? 'col-md-6 col-sm' : 'col';

    return (
        <div className="col-xlg-2 col-lg-3 col-md-4 col-sm-6 col-12 mt-4">
            <div className="border rounded shadow p-3">
                <p className="h5">{name}</p>
                <hr/>
                <div className="row">
                    <div className={`mx-auto ${metadataClassName}`}>
                        <p className="text-center"><i className="bi bi-calendar-date fs-5"/> {date}</p>
                    </div>
                    {place &&
                    <div className={`mx-auto ${metadataClassName}`}>
                        <p className="text-center"><span
                            className="material-icons align-bottom">location_on</span> {place}</p>
                    </div>
                    }
                </div>
                {children}
            </div>
        </div>
    );
};

export default Object.assign(EventCard, {
    Description: EventCardDescription,
    Annexe: {
        Link: EventCardLink,
        Modal: EventCardModal
    }
});