import React, {FunctionComponent} from 'react';
import {ExperienceModalInterface} from '../../../../data-structures/components/experience-modal.interface';
import Alinea from '../../../../components/alinea';

const MatchaModalTitle: FunctionComponent = () => (
    <span>Matcha</span>
);

const MatchaModalContext: FunctionComponent = () => (
    <p>
        <Alinea/> Second projet de la branche Web de
        l'école <a href="https://www.42lyon.fr/" target="_blank" rel="noopener noreferrer">42 Lyon</a>,
        il doit être fait en équipe de deux étudiants.
    </p>
);

const MatchaModalGoal: FunctionComponent = () => (
    <p>
        <Alinea/> Ce second projet nous introduit à un outil plus évolué pour réaliser vos
        applications
        web :
        le micro-framework.<br/>
        Nous sommes invités à réaliser, dans le langage de notre choix, un site de
        rencontres.<br/>
        Les interactions entre utilisateurs seront au coeur du projet.<br/>
        Les détails du projet sont décrits dans le <a
        href="https://gitlab.com/charles.garoux/matcha/-/blob/master/docs/matcha.fr.pdf"
        target="_blank" rel="noopener noreferrer">sujet</a>.
    </p>
);

const MatchaModalResult: FunctionComponent = () => (
    <p>
        <Alinea/> Le projet a été développé avec le micro-framework <a href="https://lumen.laravel.com"
                                                                       target="_blank" rel="noopener noreferrer">Lumen</a> de Laravel avec les
        contraintes du sujet.<br/>
        Une bibliothèque en PHP a été développées pour le projet avec en grandes parties du code réutilisable pour
        d’autre projet en Lumen ou PHP.<br/>
        <br/>
        Le projet a été <span className="fw-bold">validé par 3 correcteurs</span> durant la soutenance ainsi que des bonus :
        <ul>
            <li>Gestion de tous les genres</li>
            <li>Carte interactive</li>
            <li>Les photos de profils peuvent être des GIFs</li>
            <li>Ajout d'image de profil en "drag and drop"</li>
        </ul>
    </p>
);

const MatchaModal: ExperienceModalInterface = {
    name: 'Matcha',
    Title: MatchaModalTitle,
    Context: MatchaModalContext,
    Result: MatchaModalResult,
    Goal: MatchaModalGoal,

    info: {enjoyingNote: 4, learningNote: 3.5},
    git: {
        platform: 'Gitlab',
        link: 'https://gitlab.com/charles.garoux/matcha',
        public: true
    },
    technologiesUsed: ['HTML', 'CSS', 'Bootstrap', 'JavaScript', 'PHP', 'Lumen', 'SQL', 'MariaDB', 'Bash', 'Docker', 'Docker Compose']
};

export default MatchaModal;