import React, {FunctionComponent} from 'react';
import EventCard from '../../../components/events-page/event-card';
import Alinea from '../../../components/alinea';

const MdmRemixEvent: FunctionComponent = () => (
    <EventCard name="MDM Remix" date="12 - 13 mars 2019">
        <EventCard.Description>
            <Alinea/>Cet événement est un challenge qui nous fait revisiter l’expérience des usagers en Maison de la
            Métropole. Notre but étant d'aider les Maisons de la Métropole à trouver les solutions d'assistance sociale
            de demain.
        </EventCard.Description>
        <EventCard.Annexe.Link
            name="L’article sur l'événement"
            link="https://www.erasme.org/Workshop-MDM-remix"/>
        <EventCard.Annexe.Link
            name="Présentation du prototype"
            link="https://www.erasme.org/Une-MDM-sur-mesure"/>
    </EventCard>
);

export default MdmRemixEvent;