import React, {FunctionComponent} from 'react';
import Introduce from './introduce';
import VisitCard from './visit-card';
import PageTemplate from '../../components/page-template';

const HomePage: FunctionComponent = () => {
    return (
        <PageTemplate>
            <Introduce/>
            <VisitCard/>
            {/*<WebCV/>*/}
        </PageTemplate>
    );
};

export default HomePage;