import React, {FunctionComponent} from 'react';
import {ExperienceModalInterface} from '../../../../data-structures/components/experience-modal.interface';
import Alinea from '../../../../components/alinea';

const ApprentissagePaloItModalTitle: FunctionComponent = () => (
    <span>Apprentissage à PALO IT Lyon</span>
);

const ApprentissagePaloItModalContext: FunctionComponent = () => (
    <p>
        <Alinea/> Apprentissage de 2 ans en formation à l'école <a href="https://www.42lyon.fr/" target="blank">42 Lyon</a> en alternance chez PALO IT Lyon, une entreprise de conseil et de développement informatique agile. <br/>
    </p>
);

const ApprentissagePaloItModalGoal: FunctionComponent = () => (
    <p>
        <Alinea/> Me perfectionner dans le développement de solutions informatiques et dans la gestion de projet Agile.
    </p>
);

const ApprentissagePaloItModalResult: FunctionComponent = () => (
    <p>
        <Alinea/> J’ai travaillé sur différentes missions avec des contextes très différents (startup, e-commerce, RH, Web3, formation, …). <br/>
        <br/>
        J’ai aussi travaillé sur différents projets internes à l'entreprise, comme le développement :
        <ul>
            <li>d'un “Career Path” (système de suivi d'évolution salariale)</li>
            <li>de “Lynx” un ensemble de briques réutilisables pour le développement de projets informatiques</li>
        </ul>
        <br/>
        Ses expériences mon permis d'évoluer sur le plan technique, commercial et organisationnel.
    </p>
);

const ApprentissagePaloItModal: ExperienceModalInterface = {
    name: 'Apprentissage à PALO IT Lyon',
    Title: ApprentissagePaloItModalTitle,
    Context: ApprentissagePaloItModalContext,
    Result: ApprentissagePaloItModalResult,
    Goal: ApprentissagePaloItModalGoal,

    info: {enjoyingNote: 4, learningNote: 3.5},
    technologiesUsed: ['React', 'Node.js', 'TypeScript', 'Docker', 'Docker Compose', 'Gitlab CI', 'Azure DevOps', 'Dagger.io', 'Terraform', 'Scaleway', 'Unity']
};

export default ApprentissagePaloItModal;