import React, {FunctionComponent} from 'react';
import {ListGroup} from 'react-bootstrap';
import ExperienceModalButton from './buttons/experience-modal-button';
import ExperienceLinkButton from './buttons/experience-link-button';
import ExperiencePopoverButton from './buttons/experience-popover-button';
import ExperienceUdemyModalButton from './buttons/experience-udemy-modal-button';

const ExperienceElement: FunctionComponent = ({children}) => (
    <ListGroup.Item>
        {children}
    </ListGroup.Item>
);

export default Object.assign(ExperienceElement, {
    Button: {
        Modal: ExperienceModalButton,
        UdemyModal: ExperienceUdemyModalButton,
        Link: ExperienceLinkButton,
        Popover: ExperiencePopoverButton
    }
});