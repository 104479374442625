import {FunctionComponent} from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import HomePage from './pages/home';
import TechnologiesPage from './pages/technologies';
import EventsPage from './pages/events';

const Routes: FunctionComponent = () => {
    return (
        <Router>
            <div>
                {/* Le systeme de gestion des routes de l'application */}
                <Switch>
                    <Route exact path="/" component={HomePage}/>
                    <Route exact path="/technologies" component={TechnologiesPage}/>
                    <Route exact path="/evenements" component={EventsPage}/>
                    {/*<Route component={PageNotFound}/>*/}
                </Switch>
            </div>
        </Router>
    );
};

export default Routes;