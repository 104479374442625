import React from 'react';
import TechCard from '../../../../components/technologies-page/tech-card';
import InternshipMillionVictories from '../experience-links/internship-million-victories';
import {TechnologyCardType} from '../../../../data-structures/components/technology-card.type';

const KubernetesTechnology: TechnologyCardType = ({cardSize = 'large'}) => {
    const technologyName = 'Kubernetes';

    return (
        <TechCard className="border-warning"
                  imageSource="img/technologies/kubernetes_logo.svg"
                  technologyName={technologyName}
                  imageWidthInPercent={50}>

            <TechCard.Annexes>
                <small className="mb-0">Les technologies :</small>
                <TechCard.Annexes.Element>
                    <TechCard.Annexes.Element.Technology
                        technologyParentName={technologyName}
                        technologyName="Docker">
                        Outil de conteneurisation qui permet d’empaqueter une application et ses dépendances dans un
                        conteneur isolé.
                    </TechCard.Annexes.Element.Technology>
                </TechCard.Annexes.Element>
                <TechCard.Annexes.Element>
                    <TechCard.Annexes.Element.Technology
                        technologyParentName={technologyName}
                        technologyName="Lens">
                        IDE pour Kubernetes qui permet d'observer et manipuler le cluster ainsi que ses configurations.
                    </TechCard.Annexes.Element.Technology>
                </TechCard.Annexes.Element>
            </TechCard.Annexes>

            <TechCard.Gain>
                Cet outil me permet d’automatisation de déploiement, la montée en charge et de mise en œuvre de
                conteneurs d'application sur des clusters de serveur.<br/>
                Cela permet, en autre, d’unifier l'environnement de développement et de production.
            </TechCard.Gain>

            <TechCard.AddInfo technologyName={technologyName}>
                <TechCard.AddInfo.Affinity
                    technologyName={technologyName}
                    gradeValue={4}>
                    J'apprécie l’aspect IaC et la polyvalence de ses capacités comme gestion de montée en charge,
                    exécution périodique ou gestion de cycle de vie des conteneurs.<br/>
                    En plus des capacités internes, il peut être utilisé dans des environnement Cloud et profiter de
                    leur capacité.
                </TechCard.AddInfo.Affinity>
                <TechCard.AddInfo.Experience
                    technologyName={technologyName}
                    gradeValue={1}>
                    Je nécessite de beaucoup plus d'expérience pour être plus performant et à l'aise, mais je prévois de
                    me former sur cette technologie.
                </TechCard.AddInfo.Experience>
            </TechCard.AddInfo>
            <TechCard.Experiences.Group>
                <TechCard.Experiences.Group.Element>
                    <TechCard.Experiences.Group.Element.Button.Link
                        technologyName={technologyName}
                        experienceLinkData={InternshipMillionVictories}/>
                </TechCard.Experiences.Group.Element>
            </TechCard.Experiences.Group>
        </TechCard>
    );
};

export default KubernetesTechnology;