import React, {FunctionComponent} from 'react';
import {Link, useLocation} from 'react-router-dom';

type Props = {
    name: string
    route: string
}

const NavBarButton: FunctionComponent<Props> = ({name, route}) => {
    const {pathname} = useLocation();
    const isCurrentPage: boolean = (route === pathname);
    const linkClassName: string = (isCurrentPage) ?
        'border border-secondary rounded bg-special-blue-light shadow-lg' :
        '';
    return (
        <li className="nav-item">
            <Link className={`nav-link text-light my-auto ps-2 ` + linkClassName} to={route}>{name}</Link>
        </li>
    );
};

export default NavBarButton;