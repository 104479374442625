import React from 'react';
import TechCard from '../../../../components/technologies-page/tech-card';
import InternshipMillionVictories from '../experience-links/internship-million-victories';
import CocadminDocker from '../experience-links/cocadmin-docker';
import {TechnologyCardType} from '../../../../data-structures/components/technology-card.type';
import InternshipMegaCrea from '../experience-links/internship-megacrea';
import StationProjectModal from '../experience-modals/station-project-modal';
import ApprentissagePaloItModal from '../experience-modals/apprentisage-palo-it-modal';

const DockerTechnology: TechnologyCardType = ({cardSize = 'large'}) => {
    const technologyName = 'Docker';

    return (
        <TechCard className="border-warning"
                  imageSource="img/technologies/docker_logo_moby.svg"
                  technologyName={technologyName}
                  imageWidthInPercent={60}>

            <TechCard.Annexes>
                <small className="mb-0">Les technologies et outils :</small>
                <TechCard.Annexes.Element>
                    <TechCard.Annexes.Element.Technology
                        technologyParentName={technologyName}
                        technologyName="Docker Compose">
                        Outil de configuration et d'exécution d’application multi-conteneur Docker.
                    </TechCard.Annexes.Element.Technology>
                </TechCard.Annexes.Element>
                <TechCard.Annexes.Element>
                    <TechCard.Annexes.Element.Technology
                        technologyParentName={technologyName}
                        technologyName="Docker Desktop">
                        Logiciel d’interface graphique pour Docker contenant les outils pour travailler avec
                        Docker.<br/>
                        Il me permet principalement d’utiliser Docker sur Windows 10 (WSL2) et macOS
                    </TechCard.Annexes.Element.Technology>
                </TechCard.Annexes.Element>
            </TechCard.Annexes>

            <TechCard.Gain>
                Cet outil me permet d’empaqueter une application et ses dépendances dans un conteneur isolé.<br/>
                Cela permet, en autre, d’unifier l'environnement de développement et de production.
            </TechCard.Gain>

            <TechCard.AddInfo technologyName={technologyName}>
                <TechCard.AddInfo.Affinity
                    technologyName={technologyName}
                    gradeValue={4}>
                    J'apprécie la polyvalence de son utilisation, car l’on peut empaqueter tout ce dont on a besoin et
                    le déployer dans plusieurs environnements différents.
                </TechCard.AddInfo.Affinity>
                <TechCard.AddInfo.Experience
                    technologyName={technologyName}
                    gradeValue={3}>
                    Je nécessite plus d'expérience pour être plus performant et à l'aise, mais je l’utilise le plus
                    possible dans mes projets.
                </TechCard.AddInfo.Experience>
            </TechCard.AddInfo>
            <TechCard.Experiences.Group>
                <TechCard.Experiences.Group.Element>
                    <TechCard.Experiences.Group.Element.Button.Modal
                        technologyName={technologyName}
                        ExperienceModal={ApprentissagePaloItModal} size='lg'/>
                </TechCard.Experiences.Group.Element>
                <TechCard.Experiences.Group.Element>
                <TechCard.Experiences.Group.Element.Button.Modal
                        technologyName={technologyName}
                        ExperienceModal={StationProjectModal} size='lg'/>
                </TechCard.Experiences.Group.Element>
                <TechCard.Experiences.Group.Element>
                    <TechCard.Experiences.Group.Element.Button.Link
                        technologyName={technologyName}
                        experienceLinkData={InternshipMegaCrea}/>
                </TechCard.Experiences.Group.Element>
                <TechCard.Experiences.Group.Element>
                    <TechCard.Experiences.Group.Element.Button.Link
                        technologyName={technologyName}
                        experienceLinkData={CocadminDocker}/>
                </TechCard.Experiences.Group.Element>
                <TechCard.Experiences.Group.Element>
                    <TechCard.Experiences.Group.Element.Button.Link
                        technologyName={technologyName}
                        experienceLinkData={InternshipMillionVictories}/>
                </TechCard.Experiences.Group.Element>
            </TechCard.Experiences.Group>
        </TechCard>
    );
};

export default DockerTechnology;