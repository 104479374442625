import {FunctionComponent} from 'react';
import {ExperienceUdemyInterface} from '../../../../../data-structures/components/experience-udemy-modal.interface';
import Alinea from '../../../../../components/alinea';

const DeveloppeurReactFormationCompleteUdemyPresentation: FunctionComponent = () => (
    <div>
        <p className="mt-3">
            <Alinea/>
            Cette formation aborde les concepts de base de React à travers la création d'une application Web sans outil
            de génération de projet React.
        </p>
        <p className="mb-0">Les notions abordées sont :</p>
        <ul>
            <li>Les composant</li>
            <li>Le DOM virtuelle avec JSX</li>
            <li>Les props</li>
            <li>Les Hooks personnalisés</li>
            <li>Les routes</li>
            <li>Les formulaires</li>
            <li>Les requêtes HTTP</li>
            <li>Le déploiement</li>
        </ul>
        <p>
            <Alinea/> Les notions travaillées par la pratique en développant une application web de "Pokédex".
        </p>
    </div>
);

const DeveloppeurReactFormationCompleteUdemy: ExperienceUdemyInterface = {
    name: 'Développeur React | Formation Complète (2021)',
    certificatLink: 'https://www.udemy.com/certificate/UC-34a76ae6-8326-41da-af9b-ceff8acdd575/',
    Presentation: DeveloppeurReactFormationCompleteUdemyPresentation
};

export default DeveloppeurReactFormationCompleteUdemy;