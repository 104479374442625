import React from 'react';
import TechCard from '../../../../components/technologies-page/tech-card';
import HypertubeModal from '../experience-modals/hypertube-modal';
import InternshipMillionVictories from '../experience-links/internship-million-victories';
import {TechnologyCardType} from '../../../../data-structures/components/technology-card.type';
import InternshipMegaCrea from '../experience-links/internship-megacrea';
import StationProjectModal from '../experience-modals/station-project-modal';
import ApprentissagePaloItModal from '../experience-modals/apprentisage-palo-it-modal';

const NodeJsTechnology: TechnologyCardType = ({cardSize = 'large'}) => {
    const technologyName = 'Node.js';

    return (
        <TechCard className="border-warning"
                  imageSource="img/technologies/Nodejs_logo.svg"
                  technologyName={technologyName}
                  noMarginBottom={true}>

            <TechCard.Annexes>
                <small className="mb-0">Les technologies et modules :</small>
                <TechCard.Annexes.Element>
                    <TechCard.Annexes.Element.Technology
                        technologyParentName={technologyName}
                        technologyName="TypeScript">
                        Sur-ensemble syntaxique de JavaScript améliorant la structure et la sécurité du code.
                    </TechCard.Annexes.Element.Technology>
                </TechCard.Annexes.Element>
                <TechCard.Annexes.Element>
                    <div className="row gx-1">
                        <div className="col-6">
                            <TechCard.Annexes.Element.Technology
                                technologyParentName={technologyName}
                                technologyName="Express.js"
                                popoverPlacement="top"
                            >
                                Développement d'application Web côté serveur.
                            </TechCard.Annexes.Element.Technology>
                        </div>
                        <div className="col-6">
                            <TechCard.Annexes.Element.Technology
                                technologyParentName={technologyName}
                                technologyName="avec EJS"
                            >
                                Moteur de rendu HTML en utilisant JavaScript pour
                                Express.js.
                            </TechCard.Annexes.Element.Technology>
                        </div>
                    </div>
                </TechCard.Annexes.Element>
                <TechCard.Annexes.Element>
                    <TechCard.Annexes.Element.Technology
                        technologyParentName={technologyName}
                        technologyName="Nest.js">
                        Framework d’application Web côté serveur.
                    </TechCard.Annexes.Element.Technology>
                </TechCard.Annexes.Element>
                <TechCard.Annexes.Element>
                    <TechCard.Annexes.Element.Technology
                        technologyParentName={technologyName}
                        technologyName="Oclif">
                        Développement d’outil en ligne de commande (CLI).
                    </TechCard.Annexes.Element.Technology>
                </TechCard.Annexes.Element>
                <TechCard.Annexes.Element>
                    <TechCard.Annexes.Element.Technology
                        technologyParentName={technologyName}
                        technologyName="Stripe">
                        Solution de traitement de paiement en ligne.
                    </TechCard.Annexes.Element.Technology>
                </TechCard.Annexes.Element>
            </TechCard.Annexes>

            <TechCard.Gain>
                Node.js me permet d'être polyvalent sur la résolution de problématique
                avec des applications Web côté serveur ou des outils en ligne de commande.
            </TechCard.Gain>

            <TechCard.AddInfo technologyName={technologyName}>
                <TechCard.AddInfo.Affinity
                    technologyName={technologyName}
                    gradeValue={4.5}>
                    J'apprécie fortement ses capacités techniques (flexibilité, asynchronicité, stream, événementiel,
                    ...) et couplé avec Typescript le développement est plus confortable grâce à la structuration.<br/>
                    La richesse de la communauté apporte aussi beaucoup avec les modules publics et forum d’entraide.
                </TechCard.AddInfo.Affinity>
                <TechCard.AddInfo.Experience
                    technologyName={technologyName}
                    gradeValue={3.5}>
                    Je nécessite plus d'expérience pour être plus performant mais c’est en cours d'amélioration, car je
                    travaille actuellement sur un projet (stage MegaCrea).
                </TechCard.AddInfo.Experience>
            </TechCard.AddInfo>
            <TechCard.Experiences.Group>
                <TechCard.Experiences.Group.Element>
                    <TechCard.Experiences.Group.Element.Button.Modal
                        technologyName={technologyName}
                        ExperienceModal={ApprentissagePaloItModal} size='lg'/>
                </TechCard.Experiences.Group.Element>
                <TechCard.Experiences.Group.Element>
                    <TechCard.Experiences.Group.Element.Button.Modal
                        technologyName={technologyName}
                        ExperienceModal={StationProjectModal} size='lg'/>
                </TechCard.Experiences.Group.Element>
                <TechCard.Experiences.Group.Element>
                    <TechCard.Experiences.Group.Element.Button.Link
                        technologyName={technologyName}
                        experienceLinkData={InternshipMegaCrea}/>
                </TechCard.Experiences.Group.Element>
                <TechCard.Experiences.Group.Element>
                    <TechCard.Experiences.Group.Element.Button.Link
                        technologyName={technologyName}
                        experienceLinkData={InternshipMillionVictories}/>
                </TechCard.Experiences.Group.Element>
                <TechCard.Experiences.Group.Element>
                    <TechCard.Experiences.Group.Element.Button.Modal
                        technologyName={technologyName}
                        ExperienceModal={HypertubeModal} size='lg'/>
                </TechCard.Experiences.Group.Element>
            </TechCard.Experiences.Group>
        </TechCard>
    );
};

export default NodeJsTechnology;