import React, {FunctionComponent} from 'react';
import EventCard from '../../../components/events-page/event-card';
import Alinea from '../../../components/alinea';

const GamedayAws42LyonEvent: FunctionComponent = () => (
    <EventCard name="GameDay AWS - 42 Lyon" date="28 juillet 2020" place="42 Lyon">
        <EventCard.Description>
            <Alinea/>Le Gameday est un exercice d'apprentissage conçu pour se familiariser avec l'environnement AWS et
            se mettre au défi de trouver des moyens de résoudre les problèmes en fonction des ressources AWS mises à
            votre disposition.
        </EventCard.Description>
        <EventCard.Annexe.Link
            name="Post et photos" link="https://www.linkedin.com/posts/42-lyon-auvergne-rh%C3%B4ne-alpes_workshop-aws-42lyon-activity-6628292070496960512-PlGh"/>
    </EventCard>
);

export default GamedayAws42LyonEvent;