import React, {FunctionComponent} from 'react';
import PageTemplate from '../../components/page-template';
import Interets from './interets';
import EventsList from './events-list';

const Evenements: FunctionComponent = () => {
    return (
        <PageTemplate>
            <Interets/>
            <EventsList/>
        </PageTemplate>
    );
};

export default Evenements;