import React from 'react';
import TechCard from '../../../../components/technologies-page/tech-card';
import {TechnologyCardType} from '../../../../data-structures/components/technology-card.type';

const DrawIoTechnology: TechnologyCardType = ({cardSize = 'large'}) => {
    const technologyName = 'Draw.io';

    return (
        <TechCard className="border-warning"
                  imageSource="img/technologies/drawio_logo.svg"
                  technologyName={technologyName}
                  imageWidthInPercent={40}
                  cardSize={cardSize}>

            <TechCard.Gain>
                Draw.io est une application qui me permet de créer des schémas pour la documentation de mes projets.
            </TechCard.Gain>

            <TechCard.AddInfo technologyName={technologyName}>
                <TechCard.AddInfo.Affinity
                    technologyName={technologyName}
                    gradeValue={4}>
                    J'apprécie les capacités et la portabilité de cette application, mais elle peut être parfois
                    chronophage sur des schémas de moyenne et grande taille.
                </TechCard.AddInfo.Affinity>
                <TechCard.AddInfo.Experience
                    technologyName={technologyName}
                    gradeValue={3}>
                    Je nécessite de plus d'expérience et des cas d'utilisation spéciaux pour connaître plus en
                    profondeur les différentes capacités de l’outil, mais je l’utilise constamment dans mon travail.
                </TechCard.AddInfo.Experience>
            </TechCard.AddInfo>
        </TechCard>
    );
};

export default DrawIoTechnology;