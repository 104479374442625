import React, {FunctionComponent} from 'react';
import {Link} from 'react-router-dom';
import Alinea from '../../components/alinea';
import {HashLink} from 'react-router-hash-link';

const Introduce: FunctionComponent = () => {
    return (
        <div className="col-lg-6 offset-lg-1 col-sm-6 col-12 my-auto mb-3">
            <div className="alert alert-warning shadow" role="alert">
                <b className="text-primary">Information</b> :
                mon apprentissage se termine le 13 mars 2024, et je suis actuellement <b className="text-primary">ouvert aux opportunités de contrat</b>.
            </div>

            <div className="card">
                <h5 className="card-header">A propos de moi</h5>
                <div className="card-body">
                    <p className="card-text">
                        <Alinea/>Je m’appelle <b>Charles Garoux</b>, j'ai {new Date().getFullYear() - new Date("04/01/1999").getFullYear()} ans et j'ai toujours
                        été intéressé par
                        tout ce qui touche au numérique c'est pourquoi je suis <b>développeur</b>.
                    </p>
                    <p className="card-text">
                        <Alinea/>Le jeu vidéo, le Web, l'électronique, l'IoT et tout ce qui fait
                        partie du monde du numérique
                        m'attire. J'aime toucher à tout, mais je me dirige vers une spécialisation en <b>développement
                        Web orienté Cloud et DevOps</b> avec <b><Link to="/technologies#full-stack">un
                        profil
                        Full Stack</Link></b>.
                    </p>
                    <div className="col-md-6 col-12 d-grid gap-2 mx-auto">
                        <HashLink to="/technologies#full-stack" className="btn btn-success fw-bold" type="button"
                                  data-track-button-type="call-to-action"
                                  data-track-call-to-action-text="Voir mon profil technique"
                        >
                            <i className="bi bi-eye"/> Voir mon profil technique <i className="bi bi-tools"/>
                        </HashLink>
                    </div>
                </div>
            </div>

            <div className="card mt-3">
                <h5 className="card-header">Actuellement</h5>
                <div className="card-body">
                    <p className="card-text">
                        <Alinea/>Je suis en formation à l'école <a href="https://www.42lyon.fr/" className="fw-bold"
                                                                   target="blank">42
                        Lyon</a> depuis le 1<sup>er</sup> octobre 2018 jusqu'à la fin de mon alternance en mars 2024.<br/>
                        <br/>
                        <Alinea/>Cette alternance au sein de <a href="https://www.palo-it.com/fr/" className="fw-bold" target="blank">Palo IT Lyon
                        </a> complète ma formation et permet d'acquérir le titre
                        “<a href="https://www.francecompetences.fr/recherche/rncp/35078/" className="fw-bold" target="blank">Expert en
                        Informatique et Systèmes d’Information</a>” <b>Bac+5</b> (titre RNCP de niveau 7).<br/> <br/>
                    </p>
                </div>
            </div>

            <div className="card mt-3">
                <h5 className="card-header">42 Lyon</h5>
                <div className="card-body">
                    <p className="card-text">
                        <Alinea/>Cette formation se fait sur environ 3 ans, mais peut durer jusqu'à 5 ans comme avec une
                        alternance par exemple.
                    </p>
                    <p className="card-text">
                        <Alinea/>Cette formation <b>permet de se construire un profil professionnel nous
                        correspondant</b> grâce à <b>différentes spécialisations</b> possibles et par
                        l'<b>autonomie</b> de
                        notre parcours.
                        Les plus développés : système, algorithmie, sécurité, web, graphique.
                    </p>
                    <p className="card-text">
                        <Alinea/>La formation se fait avec <span className="font-weight-bold">de nombreux projets et en peer-learning</span>.
                        Le peer-learning est un principe d'apprentissage et d'échange de connaissance sans référentiel
                        sachant comme un professeur.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Introduce;