import React from 'react';
import TechCard from '../../../../components/technologies-page/tech-card';
import CocadminAnsible from '../experience-links/cocadmin-ansible';
import {TechnologyCardType} from '../../../../data-structures/components/technology-card.type';

const AnsibleTechnology: TechnologyCardType = ({cardSize = 'large'}) => {
    const technologyName = 'Ansible';

    return (
        <TechCard className="border-warning"
                  imageSource="img/technologies/ansible_logo.svg"
                  technologyName={technologyName}
                  imageWidthInPercent={50}
                  noMarginBottom={true}>

            {/*<TechCard.Annexes>*/}
            {/*    <small className="mb-0">Les packages Ansible :</small>*/}
            {/*    <TechCard.Annexes.Element>*/}
            {/*        <TechCard.Annexes.Element.Technology*/}
            {/*            technologyParentName={technologyName}*/}
            {/*            technologyName="XXX">*/}
            {/*            XXXXXXXXXX*/}
            {/*        </TechCard.Annexes.Element.Technology>*/}
            {/*    </TechCard.Annexes.Element>*/}
            {/*</TechCard.Annexes>*/}

            <TechCard.Gain>
                Cet outil me permet de gérer et configurer des machines à distance à partir de fichiers de
                configuration.
            </TechCard.Gain>

            <TechCard.AddInfo technologyName={technologyName}>
                <TechCard.AddInfo.Affinity
                    technologyName={technologyName}
                    gradeValue={3.5}>
                    J'apprécie la polyvalence par la richesse des packages développés par la communauté, mais j’ai des
                    difficultés avec l’utilisation du YAML.
                    Je n’ai pas encore eu l’occasion de l’utiliser dans un projet.
                </TechCard.AddInfo.Affinity>
                <TechCard.AddInfo.Experience
                    technologyName={technologyName}
                    gradeValue={1}>
                    Je nécessite plus d'expérience pour être plus performant, mais je pense à l'utiliser pour de
                    prochains projets.
                </TechCard.AddInfo.Experience>
            </TechCard.AddInfo>
            <TechCard.Experiences.Group>
                <TechCard.Experiences.Group.Element>
                    <TechCard.Experiences.Group.Element.Button.Link
                        technologyName={technologyName}
                        experienceLinkData={CocadminAnsible}/>
                </TechCard.Experiences.Group.Element>
            </TechCard.Experiences.Group>
        </TechCard>
    );
};

export default AnsibleTechnology;