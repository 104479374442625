import React, {FunctionComponent} from 'react';
import EventCard from '../../../components/events-page/event-card';
import Alinea from '../../../components/alinea';

const GamedayAwsEvent: FunctionComponent = () => (
    <EventCard name="GameDay AWS" date="24 juin 2019" place="42 Lyon">
        <EventCard.Description>
            <Alinea/>Le Gameday est un exercice d'apprentissage conçu pour se familiariser avec l'environnement AWS et
            se mettre au défi de trouver des moyens de résoudre les problèmes en fonction des ressources AWS mises à
            votre disposition.
        </EventCard.Description>
        <EventCard.Annexe.Link
            name="Page Meetup" link="https://www.meetup.com/fr-FR/AWS-Lyon-Amazon-Web-Services-User-Group/events/262181280/"/>
    </EventCard>
);

export default GamedayAwsEvent;