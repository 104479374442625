import React, {FunctionComponent} from 'react';
import TechnologyBaseGrade from './base-grade';
import {PopoverPlacementType} from '../../../../../data-structures/types/popoverPlacement.type';

type Props = {
    technologyName: string
    blockButton?: boolean
    popoverPlacement?: PopoverPlacementType
    colorTheme?: string
    gradeValue: number
    gradeMax?: number
    buttonSize?: 'sm' | 'lg'
}

/**
 * Bouton avec la note d'affinite et en popover la raison (ou autre) de la note.
 * Le props "children" contiendra la raison
 */
const TechnologyAffinityGrade: FunctionComponent<Props> = (
    props) => {
    return (
        <TechnologyBaseGrade {...props} gradeSymbole="heart" gradeType="affinity" titlePrefix="Affinité avec"/>
    );
};


export default TechnologyAffinityGrade;