import React, {FunctionComponent} from 'react';
import {ExperienceModalInterface} from '../../../../data-structures/components/experience-modal.interface';
import Alinea from '../../../../components/alinea';

const OldPortfolioTrackerModalTitle: FunctionComponent = () => (
    <span>Ancien tracker de mon Portfolio</span>
);

const OldPortfolioTrackerModalContext: FunctionComponent = () => (
    <p>
        <Alinea/> Ce micro-projet m’est venu pendant ma recherche de stage
        à <a href="https://www.42lyon.fr/" target="blank">42 Lyon</a> quand je recherchais à m’exercer sur Framework
        Serverless.
    </p>
);

const OldPortfolioTrackerModalGoal: FunctionComponent = () => (
    <p>
        <Alinea/> Pouvoir suivre les visites de mon portfolio.
    </p>
);

const OldPortfolioTrackerModalResult: FunctionComponent = () => (
    <p>
        <Alinea/> Le projet est fonctionnel en 4 jours, il fonctionne avec Bootstrap 4.<br/>
        Le tracker enregistre les interactions avec la page.<br/>
        Du code JavaScript envoie des données de visite à une application Lambda sur AWS.<br/>
        Les visites sont stockées au format JSON sur un Bucket S3. </p>
);

const OldPortfolioTrackerModal: ExperienceModalInterface = {
    name: 'Ancien tracker de mon Portfolio',
    Title: OldPortfolioTrackerModalTitle,
    Context: OldPortfolioTrackerModalContext,
    Result: OldPortfolioTrackerModalResult,
    Goal: OldPortfolioTrackerModalGoal,

    info: {enjoyingNote: 4, learningNote: 2.5},
    git: {
        public: false
    },
    technologiesUsed: ['Serverless', 'Node.js', 'JavaScript', 'AWS']
};

export default OldPortfolioTrackerModal;