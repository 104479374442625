import React, {FunctionComponent} from 'react';
import {Card} from 'react-bootstrap';
import {default as Annexes} from './annexes';
import TechnologyGain from './gain';
import AdditionalInformation from './additional-information';
import ExperienceGroup from './experiences/experiences-group';
import ExperienceDisplayModal from './experiences/displays/experience-display-modal';
import ExperienceDisplayUdemyModal from './experiences/displays/experience-display-udemy-modal';

export type CardColSize = 'medium' | 'large';

interface Props {
    className?: string
    technologyName: string
    technologyNameAcronym?: string
    imageSource: string
    imageWidthInPercent?: number
    noMarginBottom?: boolean
    cardSize?: CardColSize
    hidden?: boolean
}

const cardByNumberClass = {
    medium: 'col-lg-3 col-md-6 col-12', // Bootstrap lg = 4 by row
    large: 'col-lg-6 col-12' // Bootstrap lg = 2 by row
};

/**
 * Est le composant qui englobera tous les composants enfants (props.children)
 * de description de la technologie (image, nom, techno annexes, ...)
 */
const TechCard: FunctionComponent<Props> =
    ({className, technologyName, technologyNameAcronym, imageSource, imageWidthInPercent = 70, noMarginBottom = false, cardSize = 'large', hidden = false, children}) => (
        <div className={`px-2 ${cardByNumberClass[cardSize]} ${className} border-0 px-3 mb-3 ${hidden ? 'd-none' : ''}`}>
            <Card className="shadow border-0">
                <Card.Body>
                    <div className={`mx-auto ${noMarginBottom ? '' : 'mb-3'}`}
                         style={{width: `${imageWidthInPercent}%`}}>
                        <Card.Img variant="top" src={imageSource} alt={technologyName + ' image'}/>
                    </div>
                    <Card.Title>{technologyName} {technologyNameAcronym &&
                    <small className="fw-light">({technologyNameAcronym})</small>}</Card.Title>
                    {children}
                </Card.Body>
            </Card>
        </div>
    );

export default Object.assign(TechCard, {
    Annexes: Annexes,
    Gain: TechnologyGain,
    AddInfo: AdditionalInformation,
    Experiences: {
        Group: ExperienceGroup,
        Display: {
            Modal: ExperienceDisplayModal,
            UdemyModal: ExperienceDisplayUdemyModal
        }
    }
});