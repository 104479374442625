import {FunctionComponent} from 'react';

const FullstrackProfil: FunctionComponent = () => (
    <div>
        <div className="row justify-content-center">
            <h3 id="full-stack" className="mt-4 text-center">Mon profil Full Stack</h3>
        </div>
        <div className="row justify-content-center mt-3">
            <div className="col-xxl-7 col-lg-9 col-md-9 col-12">
                <div className="border rounded-3 shadow py-5 px-5"><a href="img/technologies/schema_mon_profile_full_stack.svg" target="_blank" rel="noopener noreferrer">
                    <img className="card-img-top" src="img/technologies/schema_mon_profile_full_stack.svg"
                         alt="Schéma décrivant mon profile full stack"/></a>
                </div>
            </div>
        </div>
    </div>
);

export default FullstrackProfil;