import React from 'react';
import TechCard from '../../../../components/technologies-page/tech-card';
import Cloud1Modal from '../experience-modals/cloud-1-modal';
import MyPortfolioModal from '../experience-modals/portfolio-modal';
import AmazonWebServicesUdemyModal from '../experience-modals/amazon-web-service-udemy-modal';
import {TechnologyCardType} from '../../../../data-structures/components/technology-card.type';

const AmazonWebServicesTechnology: TechnologyCardType = ({cardSize = 'large'}) => {
    const technologyName = 'Amazon Web Services';
    const technologyNameAcronym = 'AWS';
    return (
        <TechCard className="border-warning"
                  imageSource="img/technologies/AWS_logo.svg"
                  technologyName={technologyName} technologyNameAcronym={technologyNameAcronym}
                  imageWidthInPercent={50}>

            <TechCard.Annexes>
                <small className="mb-0">Les technologies :</small>
                <TechCard.Annexes.Element>
                    <TechCard.Annexes.Element.Technology
                        technologyParentName={technologyName}
                        technologyName="Terraform">
                        Outil d'Infrastructure As Code (IaC), il permet le déploiement d'infrastructures se basant sur
                        des fichiers de configuration.
                    </TechCard.Annexes.Element.Technology>
                </TechCard.Annexes.Element>
            </TechCard.Annexes>

            <TechCard.Gain>
                Amazon Web Service me permet de déployer et héberger des applications dans le Cloud.<br/>
                Il propose de nombreux services qui permettent de résoudre un large spectre de problématiques.<br/>
                Architecturalement il permet des infrastructures élastiques et tolérantes aux pannes.
            </TechCard.Gain>

            <TechCard.AddInfo technologyName={technologyName}>
                <TechCard.AddInfo.Affinity
                    technologyName={technologyName}
                    gradeValue={4.5}>
                    De nombreux services complets sont proposés et la communauté est très active,
                    mais je n'apprécie pas particulièrement l'expérience utilisateur proposée par l’interface Web.
                </TechCard.AddInfo.Affinity>
                <TechCard.AddInfo.Experience
                    technologyName={technologyName}
                    gradeValue={2}>
                    Je nécessite beaucoup plus d'expérience pour être plus performant et à l'aise,
                    mais je pense à l'utiliser pour de prochains projets.
                </TechCard.AddInfo.Experience>
            </TechCard.AddInfo>
            <TechCard.Experiences.Group>
                <TechCard.Experiences.Group.Element>
                    <TechCard.Experiences.Group.Element.Button.Modal
                        technologyName={technologyName}
                        ExperienceModal={MyPortfolioModal} size='lg'/>
                </TechCard.Experiences.Group.Element>
                <TechCard.Experiences.Group.Element>
                    <TechCard.Experiences.Group.Element.Button.Modal
                        technologyName={technologyName}
                        ExperienceModal={Cloud1Modal} size='lg'/>
                </TechCard.Experiences.Group.Element>
                <TechCard.Experiences.Group.Element>
                    <TechCard.Experiences.Group.Element.Button.UdemyModal
                        technologyName={technologyName}
                        ExperienceUdemyModal={AmazonWebServicesUdemyModal} size='lg'/>
                </TechCard.Experiences.Group.Element>
            </TechCard.Experiences.Group>
        </TechCard>
    );
};

export default AmazonWebServicesTechnology;