import {FunctionComponent} from 'react';
import {ExperienceUdemyInterface} from '../../../../../data-structures/components/experience-udemy-modal.interface';
import Alinea from '../../../../../components/alinea';

const AwsConceptsUdemyPresentation: FunctionComponent = () => (
    <div>
        <p className="mt-3">
            <Alinea/> Cette formation aborde les concepts fondamentaux d’AWS et du Cloud. L’on y découvre l'intérêt du
            Cloud et de ces capacités comme :
        </p>
        <ul>
            <li>La haute disponibilité</li>
            <li>La tolérance à la faute</li>
            <li>La scalabilité</li>
            <li>L'élasticité</li>
        </ul>
        <p>
            <Alinea/> L’on nous présente rapidement des services de base d’AWS (VPC, EC2, RDS et S3) et pour terminer
            sur une présentation de l’infrastructure globale d’AWS.
        </p>
    </div>
);

const AwsConceptsUdemy: ExperienceUdemyInterface = {
    name: 'AWS Concepts',
    certificatLink: 'https://www.udemy.com/certificate/UC-7fd3152c-956d-4bc1-a751-c1be04ce1e54/',
    Presentation: AwsConceptsUdemyPresentation
};

export default AwsConceptsUdemy;