import React from 'react';
import TechCard from '../../../../components/technologies-page/tech-card';
import {TechnologyCardType} from '../../../../data-structures/components/technology-card.type';
import ApprentissagePaloItModal from '../experience-modals/apprentisage-palo-it-modal';

const FlutterTechnology: TechnologyCardType = ({cardSize = 'large'}) => {
    const technologyName = 'Flutter';

    return (
        <TechCard className="border-warning"
                  imageSource="img/technologies/flutter_logo.svg"
                  technologyName={technologyName}
                  noMarginBottom={true}
                  imageWidthInPercent={30}>

            {/* <TechCard.Annexes>
                <small className="mb-0">Les technologies et modules :</small>
                <TechCard.Annexes.Element>
                    <TechCard.Annexes.Element.Technology
                        technologyParentName={technologyName}
                        technologyName="TypeScript">
                        Sur-ensemble syntaxique de JavaScript améliorant la structure et la sécurité du code.
                    </TechCard.Annexes.Element.Technology>
                </TechCard.Annexes.Element>
            </TechCard.Annexes> */}

            <TechCard.Gain>
            Le kit de développement Flutter me permet de développer des applications côté client multi-plateformes sur mobile, web et bureau.
            </TechCard.Gain>

            <TechCard.AddInfo technologyName={technologyName}>
                <TechCard.AddInfo.Affinity
                    technologyName={technologyName}
                    gradeValue={4}>
                    J'apprécie l’aspect structurant qui permet de réutilisabilité de composant développer et le fait de pouvoir
                    développer pour plusieurs plateformes.
                </TechCard.AddInfo.Affinity>
                <TechCard.AddInfo.Experience
                    technologyName={technologyName}
                    gradeValue={0.5}>
                    Je nécessite de beaucoup plus d'expérience pour être performant et à l'aise, mais je pense à
                    l'utiliser pour de prochains projets.
                </TechCard.AddInfo.Experience>
            </TechCard.AddInfo>
            <TechCard.Experiences.Group>
                <TechCard.Experiences.Group.Element>
                    <TechCard.Experiences.Group.Element.Button.Modal
                        technologyName={technologyName}
                        ExperienceModal={ApprentissagePaloItModal} size='lg'/>
                </TechCard.Experiences.Group.Element>
            </TechCard.Experiences.Group>
        </TechCard>
    );
};

export default FlutterTechnology;