import React, {FunctionComponent} from 'react';
import {ListGroup, Modal} from 'react-bootstrap';
import {ExperienceModalInterface} from '../../../../../data-structures/components/experience-modal.interface';
import Collapse from '../../../../collapse';
import Grade from '../../../../grade';

type Props = {
    show: boolean
    setShow: React.Dispatch<React.SetStateAction<boolean>>
    ExperienceModal: ExperienceModalInterface
    size?: 'sm' | 'lg' | 'xl';
}

const ExperienceDisplayModal: FunctionComponent<Props> = ({show, setShow, ExperienceModal, size}) => {
    function handleClose() {
        setShow(false);
    }

    return (
        <Modal size={size} show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{ExperienceModal.Title({})}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <span className="fw-bold">Contexte :</span>
                    {ExperienceModal.Context({})}
                </div>
                <div>
                    <span className="fw-bold">Objectif :</span>
                    {ExperienceModal.Goal({})}
                </div>
                <hr/>
                <div>
                    <span className="fw-bold">Résultat :</span>
                    {ExperienceModal.Result({})}
                </div>
                <hr/>
                <div className="d-grid gap-2">
                    <Collapse variant="info" buttonText="Information complémentaire" blockButton={true}>
                        <ListGroup>
                            {ExperienceModal.technologiesUsed && ExperienceModal.technologiesUsed.length > 0 &&
                            <ListGroup.Item>
                                {ExperienceModal.technologiesUsed.map((technologyName, index) => (
                                    <li key={index}
                                        className="list-inline-item badge rounded-pill bg-info text-dark mb-1">{technologyName}</li>
                                ))}
                            </ListGroup.Item>
                            }
                            <ListGroup.Item>
                                Apprentissage avec cette expérience : <Grade className="mx-auto" iconClassName="mx-1"
                                                                             value={ExperienceModal.info.learningNote}
                                                                             max={5}
                                                                             symbole="book"/>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                Plaisir apporté par l'expérience : <Grade className="mx-auto" iconClassName="mx-1"
                                                                          value={ExperienceModal.info.enjoyingNote}
                                                                          max={5}
                                                                          symbole="heart"/>
                            </ListGroup.Item>
                            {ExperienceModal.git && <ListGroup.Item>
                                {ExperienceModal.git.public ? (
                                    <span>Le code source est dans un dépôt git publique <a
                                        href={ExperienceModal.git.link}
                                        target='_blank'
                                        rel="noopener noreferrer">sur {ExperienceModal.git.platform}</a>
                                    </span>
                                ) : (
                                    <span>Le code source est dans un dépôt git privé</span>
                                )}
                            </ListGroup.Item>}
                        </ListGroup>
                    </Collapse>
                </div>
            </Modal.Body>

        </Modal>
    );
};

export default ExperienceDisplayModal;