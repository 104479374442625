import React from 'react';
import TechCard from '../../../../components/technologies-page/tech-card';
import MatchaModal from '../experience-modals/matcha-modal';
import MdmRemixModal from '../experience-modals/mdm-remix-modal';
import CamagruModal from '../experience-modals/camagru-modal';
import Internship303EventModal from '../experience-modals/303event-modal';
import GestHebergModal from '../experience-modals/GestHeberg-modal';
import {TechnologyCardType} from '../../../../data-structures/components/technology-card.type';

const PhpTechnology: TechnologyCardType = ({cardSize = 'large'}) => {
    const technologyName = 'PHP';

    return (
        <TechCard className="border-warning"
                  imageSource="img/technologies/php_logo.svg"
                  technologyName={technologyName}
                  noMarginBottom={true}
                  imageWidthInPercent={60}
                  hidden>

            <TechCard.Annexes>
                <small className="mb-0">Les technologies :</small>
                <TechCard.Annexes.Element>
                    <TechCard.Annexes.Element.Technology
                        technologyParentName={technologyName}
                        technologyName="Laravel">
                        Framework de développement d’application Web en “MVC” (Modèle-vue-contrôleur).
                    </TechCard.Annexes.Element.Technology>
                </TechCard.Annexes.Element>
                <TechCard.Annexes.Element>
                    <TechCard.Annexes.Element.Technology
                        technologyParentName={technologyName}
                        technologyName="Lumen">
                        Micro-framework alléger basé sur Laravel spécialisé dans les micro service et API.
                    </TechCard.Annexes.Element.Technology>
                </TechCard.Annexes.Element>
            </TechCard.Annexes>

            <TechCard.Gain>
                PHP me permet de développer principalement des applications Web
                côté serveur monolithiques ou des petits scripts utilitaires.
            </TechCard.Gain>

            <TechCard.AddInfo technologyName={technologyName}>
                <TechCard.AddInfo.Affinity
                    technologyName={technologyName}
                    gradeValue={2.5}>
                    J'apprécie l'aspect structuré avec une POO complète (héritage, abstraction, ...),
                    mais ne me permet pas une grande polyvalence dans la résolution de problème.
                </TechCard.AddInfo.Affinity>
                <TechCard.AddInfo.Experience
                    technologyName={technologyName}
                    gradeValue={2}>
                    Je nécessite plus d'expérience pour être plus performant
                    et je n’utilise actuellement plus cette technologie.
                </TechCard.AddInfo.Experience>
            </TechCard.AddInfo>
            <TechCard.Experiences.Group>
                <TechCard.Experiences.Group.Element>
                    <TechCard.Experiences.Group.Element.Button.Modal
                        technologyName={technologyName} size='lg' ExperienceModal={MatchaModal}/>
                </TechCard.Experiences.Group.Element>
                <TechCard.Experiences.Group.Element>
                    <TechCard.Experiences.Group.Element.Button.Modal
                        technologyName={technologyName} size='lg' ExperienceModal={MdmRemixModal}/>
                </TechCard.Experiences.Group.Element>
                <TechCard.Experiences.Group.Element>
                    <TechCard.Experiences.Group.Element.Button.Modal
                        technologyName={technologyName} size='lg' ExperienceModal={CamagruModal}/>
                </TechCard.Experiences.Group.Element>
                <TechCard.Experiences.Group.Element>
                    <TechCard.Experiences.Group.Element.Button.Modal
                        technologyName={technologyName} size='lg'
                        ExperienceModal={Internship303EventModal}/>
                </TechCard.Experiences.Group.Element>
                <TechCard.Experiences.Group.Element>
                    <TechCard.Experiences.Group.Element.Button.Modal
                        technologyName={technologyName} size='lg' ExperienceModal={GestHebergModal}/>
                </TechCard.Experiences.Group.Element>
            </TechCard.Experiences.Group>
        </TechCard>
    );
};

export default PhpTechnology;