import React from 'react';
import TechCard from '../../../../components/technologies-page/tech-card';
import ServerlessFrameworkUdemyModal from '../experience-modals/amazon-web-service-udemy-modal';
import OldPortfolioTrackerModal from '../experience-modals/old-portfolio-tracker-modal';
import {TechnologyCardType} from '../../../../data-structures/components/technology-card.type';

const ServerlessFrameworkTechnology: TechnologyCardType = ({cardSize = 'large'}) => {
    const technologyName = 'Framework Serverless';

    return (
        <TechCard className="border-warning"
                  imageSource="img/technologies/serverless_logo.png"
                  technologyName={technologyName}
                  imageWidthInPercent={40}>

            <TechCard.Annexes>
                <small className="mb-0">Les fournisseur Cloud et environnement d'exécution :</small>
                <TechCard.Annexes.Element>
                    <TechCard.Annexes.Element.Technology
                        technologyParentName={technologyName}
                        technologyName="Node.js">
                        Un environnement d'exécution JavaScript côté serveur.
                    </TechCard.Annexes.Element.Technology>
                </TechCard.Annexes.Element>
                <TechCard.Annexes.Element>
                    <TechCard.Annexes.Element.Technology
                        technologyParentName={technologyName}
                        technologyName="Amazon Web Service">
                        Un fournisseur Cloud proposé par une division du groupe Amazon.
                    </TechCard.Annexes.Element.Technology>
                </TechCard.Annexes.Element>
            </TechCard.Annexes>

            <TechCard.Gain>
                Le framework Serverless me permet de développer et déployer rapidement des projets en “Serverless” comme
                des API HTTP ou des applications basées sur des événements Cloud.
            </TechCard.Gain>

            <TechCard.AddInfo technologyName={technologyName}>
                <TechCard.AddInfo.Affinity
                    technologyName={technologyName}
                    gradeValue={4.5}>
                    J'apprécie l’aspect Infrastructure As Code (IaC) du framework et sa simplicité d’utilisation, mais
                    la compatibilité de fournisseur Cloud est limitée.<br/>
                    <div className="border rounded mt-1 ps-1 fst-italic">
                        D’après mes dernières recherches, le framework a des limites de capacité avec des fournisseurs
                        comme GCP avec peu de régions pris en charge.<br/>
                        (Les limite vont surement disparaitre avec la suite du développement)
                    </div>
                </TechCard.AddInfo.Affinity>
                <TechCard.AddInfo.Experience
                    technologyName={technologyName}
                    gradeValue={2}>
                    Je nécessite beaucoup plus d'expérience pour être plus performant et à l'aise,
                    mais je pense à l'utiliser pour de prochains projets.
                </TechCard.AddInfo.Experience>
            </TechCard.AddInfo>
            <TechCard.Experiences.Group>
                <TechCard.Experiences.Group.Element>
                    <TechCard.Experiences.Group.Element.Button.Modal
                        technologyName={technologyName}
                        ExperienceModal={OldPortfolioTrackerModal} size='lg'/>
                </TechCard.Experiences.Group.Element>
                <TechCard.Experiences.Group.Element>
                    <TechCard.Experiences.Group.Element.Button.UdemyModal
                        technologyName={technologyName}
                        ExperienceUdemyModal={ServerlessFrameworkUdemyModal} size='lg'/>
                </TechCard.Experiences.Group.Element>
            </TechCard.Experiences.Group>
        </TechCard>
    );
};

export default ServerlessFrameworkTechnology;