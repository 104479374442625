import React, {FunctionComponent} from 'react';
import EventCard from '../../../components/events-page/event-card';
import Alinea from '../../../components/alinea';

const LearningDayAwsEvent: FunctionComponent = () => (
    <EventCard name="Learning Day AWS" date="11 décembre 2020">
        <EventCard.Description>
            <Alinea/>Une journée de formation gratuite pour découvrir, comprendre et expérimenter le Cloud AWS.
        </EventCard.Description>
        <EventCard.Annexe.Link
            name="L’article sur l'événement" link="https://blog.d2si.io/2020/02/10/learning-day-aws-a-lyon/"/>
    </EventCard>
);

export default LearningDayAwsEvent;