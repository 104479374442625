import React, {FunctionComponent} from 'react';
import {ExperienceModalInterface} from '../../../../data-structures/components/experience-modal.interface';
import Alinea from '../../../../components/alinea';

const MyPortfolioModalTitle: FunctionComponent = () => (
    <span>Mon Portfolio</span>
);

const MyPortfolioModalContext: FunctionComponent = () => (
    <p>
        <Alinea/> J’ai besoin régulièrement d’un site internet qui me présente mon profil professionnel plus en détail
        qu’un CV.
    </p>
);

const MyPortfolioModalGoal: FunctionComponent = () => (
    <p>
        <Alinea/> Faire un site internet avec mes compétences techniques les plus récentes possible.
        L'automatisation doit faciliter les mises à jour et l'hébergement permet une optimisation des coûts.
    </p>
);

const MyPortfolioModalResult: FunctionComponent = () => (
    <p>
        <Alinea/> Un site en <span className="fw-bold">React</span> et <span
        className="fw-bold">Bootstrap 5</span> déployé sur <span className="fw-bold">Amazon Web Service</span> par une
        pipeline <span className="fw-bold">Gitlab CI</span>.<br/>
        <br/>
        <Alinea/> L'hébergement se fait sur un Bucket S3, distribué par le CDN CloudFront et le certificat SSL est géré
        avec AWS Certificat Manager.
    </p>
);

const MyPortfolioModal: ExperienceModalInterface = {
    name: 'Mon Portfolio',
    Title: MyPortfolioModalTitle,
    Context: MyPortfolioModalContext,
    Result: MyPortfolioModalResult,
    Goal: MyPortfolioModalGoal,

    info: {enjoyingNote: 4.5, learningNote: 4},
    git: {
        public: true,
        platform: 'Gitlab',
        link: 'https://gitlab.com/portfolio-charles-garoux/portfolio'
    },
    technologiesUsed: ['HTML', 'CSS', 'Bootstrap', 'React', 'AWS', 'Gitlab CI']
};

export default MyPortfolioModal;