import React from 'react';
import TechCard from '../../../../components/technologies-page/tech-card';
import {TechnologyCardType} from '../../../../data-structures/components/technology-card.type';

const ShellTechnology: TechnologyCardType = ({cardSize = 'large'}) => {
    const technologyName = 'Shell et script Shell';

    return (
        <TechCard className="border-warning"
                  imageSource="img/technologies/bash_logo.svg"
                  technologyName={technologyName}
                  imageWidthInPercent={40}
                  cardSize={cardSize}>

            <TechCard.Annexes>
                <small className="mb-0">Les shell et outils :</small>
                <TechCard.Annexes.Element>
                    <TechCard.Annexes.Element.Technology
                        technologyParentName={technologyName}
                        technologyName="Sh">
                        Shell par défaut de beaucoup de systèmes d’exploitation Unix et distribution Linux.
                    </TechCard.Annexes.Element.Technology>
                </TechCard.Annexes.Element>
                <TechCard.Annexes.Element>
                    <TechCard.Annexes.Element.Technology
                        technologyParentName={technologyName}
                        technologyName="Bash">
                        Shell Unix du projet GNU basé sur “sh” donc le Shell par défaut de beaucoup de systèmes
                        d’exploitation Unix et distribution Linux.
                    </TechCard.Annexes.Element.Technology>
                </TechCard.Annexes.Element>
                <TechCard.Annexes.Element>
                    <TechCard.Annexes.Element.Technology
                        technologyParentName={technologyName}
                        technologyName="Zsh">
                        Shell Unix reprenant la plus dès fonction pratique de “Bash” et d’autre Shell et de nouvelles
                        fonctionnalités.<br/>
                        Le plus souvent, le Shell de mon poste de travail.
                    </TechCard.Annexes.Element.Technology>
                </TechCard.Annexes.Element>
                <TechCard.Annexes.Element>
                    <TechCard.Annexes.Element.Technology
                        technologyParentName={technologyName}
                        technologyName="SSH">
                        Programme et protocole de communication sécurisée pour accéder au Shell de machine à distance.
                    </TechCard.Annexes.Element.Technology>
                </TechCard.Annexes.Element>
            </TechCard.Annexes>

            <TechCard.Gain>
                Le Shell me permet d'être plus rapide et productif avec de la manipulation de système de
                fichiers, de l’utilisation de programmes et de l’automatisation de tâche avec des scripts.
            </TechCard.Gain>

            <TechCard.AddInfo technologyName={technologyName}>
                <TechCard.AddInfo.Affinity
                    technologyName={technologyName}
                    gradeValue={4}>
                    J'apprécie le côté performant et automatisable avec des scripts ainsi que la possibilité de combiner
                    diverses commandes et programmes.
                </TechCard.AddInfo.Affinity>
                <TechCard.AddInfo.Experience
                    technologyName={technologyName}
                    gradeValue={3}>
                    Je nécessite de plus d'expérience et des cas d'utilisation spéciaux pour connaître plus en
                    profondeur les différents Shell, mais je l’utilise constamment dans mon travail.
                </TechCard.AddInfo.Experience>
            </TechCard.AddInfo>
        </TechCard>
    );
};

export default ShellTechnology;