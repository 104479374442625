import React, {FunctionComponent} from 'react';
import {ListGroup, Modal} from 'react-bootstrap';
import {
    ExperienceUdemyModalInterface,
    ExperienceUdemyInterface
} from '../../../../../data-structures/components/experience-udemy-modal.interface';
import {sanitiseNameForId} from '../../../../../lib/html-utils';

type Props = {
    show: boolean
    setShow: React.Dispatch<React.SetStateAction<boolean>>
    UdemyExperienceModal: ExperienceUdemyModalInterface
    size?: 'sm' | 'lg' | 'xl';
}

const ExperienceDisplayUdemyModal: FunctionComponent<Props> = ({show, setShow, UdemyExperienceModal, size}) => {
    function handleClose() {
        setShow(false);
    }

    return (
        <Modal size={size} show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Certificats de suivie et présentations des formations Udemy</Modal.Title>
            </Modal.Header>
            <ListGroup variant="flush">
                {UdemyExperienceModal.udemyExperiences.map((udemyExperience: ExperienceUdemyInterface, index) => (
                    <ListGroup.Item key={index}>
                        <div className="btn-group">
                            <a type="button" className="btn btn-outline-dark"
                               href={udemyExperience.certificatLink}
                               target="_blank" rel="noopener noreferrer" title={`Certificat ${udemyExperience.name}`}>
                                <span className="font-weight-bold">{udemyExperience.name}</span>
                                <img src="svg/certificate-outline.svg" alt="icon de certificat"/>
                            </a>
                            <img className="btn btn-info" data-bs-toggle="collapse"
                                 data-bs-target={`#collapse${sanitiseNameForId(udemyExperience.name)}`}
                                 aria-controls={`collapse${sanitiseNameForId(udemyExperience.name)}`}
                                 src="svg/chevron-down-circle-outline.svg" alt="icon bouton de flèche vers le cas"/>
                        </div>
                        <div className="collapse"
                             id={`collapse${sanitiseNameForId(udemyExperience.name)}`}>
                            {udemyExperience.Presentation({})}
                        </div>
                    </ListGroup.Item>
                ))}
            </ListGroup>
        </Modal>
    );
};

export default ExperienceDisplayUdemyModal;