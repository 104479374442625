import React, {FunctionComponent} from 'react';

type BootstrapIconClass = string

export type SymboleType = 'star' | 'heart' | 'book'

interface SymbolInterface {
    filled: BootstrapIconClass
    half: BootstrapIconClass
    empty: BootstrapIconClass
}

interface Props {
    className?: string
    iconClassName?: string
    value: number
    max: number
    symbole: SymboleType
}

const heart: SymbolInterface = {
    filled: 'bi bi-heart-fill',
    half: 'bi bi-heart-half',
    empty: 'bi bi-heart'
};

const star: SymbolInterface = {
    filled: 'bi bi-star-fill',
    half: 'bi bi-star-half',
    empty: 'bi bi-star'
};

const book: SymbolInterface = {
    filled: 'bi bi-book-fill',
    half: 'bi bi-book-half',
    empty: 'bi bi-book'
};

const Grade: FunctionComponent<Props> = ({className, iconClassName, value, max, symbole}) => {
    let gradeSymbole: SymbolInterface;
    if (symbole === 'star')
        gradeSymbole = star;
    else if (symbole === 'heart')
        gradeSymbole = heart;
    else if (symbole === 'book')
        gradeSymbole = book;

    let icons: BootstrapIconClass[] = Array.from({length: max},
        (_, index) =>
            (index < value && value - index >= 1) ? gradeSymbole.filled :
                ((value - index > 0) ? gradeSymbole.half : // If grade is a float
                    gradeSymbole.empty)
    );

    return (
        <span className={className}>
            {icons.map((icon: BootstrapIconClass, index) => (
                <i key={index} className={icon + ' ' + iconClassName}/>
            ))}
        </span>
    );
};

export default Grade;