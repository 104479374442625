import React, {FunctionComponent} from 'react';

/**
 * Paragraphe qui décrit ce que me permet cette technologie
 */
const TechnologyGain: FunctionComponent = ({children}) => (
    <div className="mx-2 my-2">
        {children}
    </div>
);

export default TechnologyGain;