import React, {FunctionComponent} from 'react';
import {ListGroup} from 'react-bootstrap';
import ExperienceElement from './experience-element';

const ExperiencesGroup: FunctionComponent = ({children}) => (
    <div>
        <h6>Mes expériences :</h6>
        <ListGroup>
            {children}
        </ListGroup>
    </div>
);


export default Object.assign(ExperiencesGroup, {
    Element: ExperienceElement
});