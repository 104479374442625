import {FunctionComponent} from 'react';
import {Link} from 'react-router-dom';

type Props = {
    isExternalLink?: boolean
    link: string
}

const VisitCardLink: FunctionComponent<Props> = ({isExternalLink = false, link, children}) => {
    let linkData;
    if (isExternalLink)
        linkData = {pathname: link};
    else
        linkData = link;
    return (
        <li className="list-group-item bg-special-blue">
            <div className="d-grid">
                <Link className="btn btn-block btn-primary my-1 mx-1"
                      to={linkData}
                      target="_blank" rel="noopener noreferrer">
                    {children}
                </Link>
            </div>
        </li>
    );
};

export default VisitCardLink;