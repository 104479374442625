import React, {FunctionComponent} from 'react';
import {ExperienceModalInterface} from '../../../../data-structures/components/experience-modal.interface';
import Alinea from '../../../../components/alinea';

const StationProjectModalTitle: FunctionComponent = () => (
    <span>Station Project</span>
);

const StationProjectModalContext: FunctionComponent = () => (
    <p>
        <Alinea/> Pour une candidature pour une alternance dans l'entreprise PALO IT, il m’a été demandé de suivre un
        sujet pour développer un projet et montrer mes capacités.
    </p>
);

const StationProjectModalGoal: FunctionComponent = () => (
    <p>
        <Alinea/> Le projet est l'infrastructure back-end d’une application mettant à disposition des données.
    </p>
);

const StationProjectModalResult: FunctionComponent = () => (
    <p>
        <Alinea/> Une API en <span className="fw-bold">Node.js</span> et <span
        className="fw-bold">TypeScript</span> lisant une base de données <span className="fw-bold">MariaDB</span>,
        produisant des logs gère et visualisable par une stack <span className="fw-bold">ELK</span> (<span
        className="fw-bold">Elasticsearch</span>, <span className="fw-bold">Logstash</span> et <span
        className="fw-bold">Kibana</span>).<br/>
        <Alinea/> Un service <span className="fw-bold">Elasticsearch</span> qui index les données de la base de
        données <span className="fw-bold">MariaDB</span> pour des recherches par texte.<br/>
        <Alinea/> Une infrastructure <span className="fw-bold">Cloud</span> sur <span
        className="fw-bold">Google Cloud Platform</span> est déployée avec <span
        className="fw-bold">Terraform</span> pour accueillir les conteneurs <span
        className="fw-bold">Docker</span> orchestrée par <span className="fw-bold">Docker Compose</span>.<br/>
        <br/>
        <Alinea/> <span className="fw-light">En ce qui concerne l'alternance, le processus a dû être arrêté à cause de chamboulement dans        l’entreprise rendant impossible d’avoir un nouvel alternant.</span>
    </p>
);

const StationProjectModal: ExperienceModalInterface = {
    name: 'Station Project',
    Title: StationProjectModalTitle,
    Context: StationProjectModalContext,
    Result: StationProjectModalResult,
    Goal: StationProjectModalGoal,

    info: {enjoyingNote: 4.5, learningNote: 3},
    git: {
        public: true,
        link: 'https://gitlab.com/charles.garoux/test-technique-palo-it',
        platform: 'Gitlab'
    },
    technologiesUsed: ['Node.js', 'Nest.js','TypeScript', 'MariaDB', 'Elasticsearch', 'Logstash', 'Kibana', 'Docker', 'Docker Compose', 'Terraform', 'GCP', 'Bash']
};

export default StationProjectModal;