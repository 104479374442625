import React, {FunctionComponent} from 'react';
import {ExperiencePopoverInterface} from '../../../../../data-structures/components/experience-popover.interface';
import {Popover} from 'react-bootstrap';
import {sanitiseNameForId} from '../../../../../lib/html-utils';

type Props = {
    ExperiencePopover: ExperiencePopoverInterface
}

const ExperienceDisplayPopover: FunctionComponent<Props> = ({ExperiencePopover}) => {
    return (
        <Popover id={`${sanitiseNameForId(ExperiencePopover.name)}-basic`}>
            <Popover.Header as="h3">{ExperiencePopover.Title({})}</Popover.Header>
            <Popover.Body>
                {ExperiencePopover.Description({})}
                <ul className="list-inline mb-0">
                    {ExperiencePopover.technologies.map((technology, index) => (
                        <li key={index} className="list-inline-item badge rounded-pill bg-info text-dark">{technology}</li>
                    ))}
                </ul>
            </Popover.Body>
        </Popover>
    );
};

export default ExperienceDisplayPopover;