import React, {FunctionComponent} from 'react';
import TechnologyPartHeader from './technology-part-header';
import NodejsTechnology from './technology-cards/node-js-technology';
import PhpTechnology from './technology-cards/php-technology';
import GoogleCloudPlatformTechnology from './technology-cards/google-cloud-platform-technology';
import AmazonWebServicesTechnology from './technology-cards/amazon-web-services-technology';
import ServerlessFrameworkTechnology from './technology-cards/serverless-framework-technology';
import TerraformTechnology from './technology-cards/terraform-technology';
import AnsibleTechnology from './technology-cards/ansible-technology';
import DockerTechnology from './technology-cards/docker-technology';
import KubernetesTechnology from './technology-cards/kubernetes-technology';
import ReactTechnology from './technology-cards/react-technology';
import ShellTechnology from './technology-cards/shell-technology';
import PhpStormTechnology from './technology-cards/phpstorm-technology';
import GitTechnology from './technology-cards/git-technology';
import GitKrakenTechnology from './technology-cards/gitkraken-technology';
import LensTechnology from './technology-cards/lens-technology';
import PostmanTechnology from './technology-cards/postman-technology';
import GitlabCiTechnology from './technology-cards/gitlab-ci-technology';
import DrawIoTechnology from './technology-cards/drawio-technology';
import VScodeTechnology from './technology-cards/vscode-technology';
import FlutterTechnology from './technology-cards/flutter-technology';
import DaggerIoTechnology from './technology-cards/dagger-io-technology';

const TechnologyPart: FunctionComponent = () => (
    <div>
        <TechnologyPartHeader/>
        <div className="row mt-4 border-top border-bottom border-info">
            <div className="border-end border-info col-sm-6 col-12">
                <div className="row justify-content-center mt-2 mb-3">
                    <h2 className="text-center font-weight-bold">Développement Web</h2>
                </div>
                <div className="row">
                    <NodejsTechnology/>
                    <ReactTechnology/>
                    <PhpTechnology/>
                    <ServerlessFrameworkTechnology/>
                    <FlutterTechnology/>
                    {/* WIP */}
                </div>
            </div>

            <div className="col-sm-6 col-12">
                <div className="row justify-content-center mt-2 mb-3">
                    <h2 className="text-center font-weight-bold">DevOps</h2>
                </div>
                <div className="row">
                    <AmazonWebServicesTechnology/>
                    <GoogleCloudPlatformTechnology/>
                    <TerraformTechnology/>
                    <AnsibleTechnology/>
                    <DockerTechnology/>
                    <KubernetesTechnology/>
                    <GitlabCiTechnology/>
                    <DaggerIoTechnology/>
                    {/* WIP */}
                </div>
            </div>
        </div>

        <div className="row mt-4 border-top border-bottom border-info">
            <div className="row justify-content-center mt-2 mb-3">
                <h2 className="text-center font-weight-bold">Mes outils</h2>
            </div>
            <div className="row">
                <ShellTechnology cardSize={'medium'}/>
                <GitTechnology cardSize={'medium'}/>
                <VScodeTechnology cardSize={'medium'}/>
                <PhpStormTechnology cardSize={'medium'}/>
                <GitKrakenTechnology cardSize={'medium'}/>
                <LensTechnology cardSize={'medium'}/>
                <PostmanTechnology cardSize={'medium'}/>
                <DrawIoTechnology cardSize={'medium'}/>
                {/* WIP */}
            </div>

        </div>

    </div>
);

export default TechnologyPart;