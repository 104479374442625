import React, {FunctionComponent} from 'react';
import {Button, OverlayTrigger} from 'react-bootstrap';
import {ExperiencePopoverInterface} from '../../../../../data-structures/components/experience-popover.interface';
import ExperienceDisplayPopover from '../displays/experience-display-popover';
import {OverlayChildren} from 'react-bootstrap/Overlay';
import {PopoverPlacementType} from '../../../../../data-structures/types/popoverPlacement.type';

type Props = {
    ExperiencePopover: ExperiencePopoverInterface
    placement?: PopoverPlacementType;
    /**
     * Only for tracking
     */
    technologyName: string
}

const ExperiencePopoverButton: FunctionComponent<Props> = ({ExperiencePopover, placement = 'auto', technologyName}) => {
    return (
        <div className="d-grid gap-2">
            <OverlayTrigger trigger="click" placement={placement} overlay={
                ExperienceDisplayPopover({ExperiencePopover: ExperiencePopover}) as OverlayChildren
            }>
                <Button variant="info"
                        data-track-component="ExperiencePopoverButton"
                        data-track-experience={ExperiencePopover.name}
                        data-track-technology={technologyName}
                >
                    <span className="text-light">{ExperiencePopover.name}</span>
                </Button>
            </OverlayTrigger>
        </div>
    );
};

export default ExperiencePopoverButton;