import React, {FunctionComponent} from 'react';
import AnnexTechnology from './annex-technology';
import {ListGroup} from 'react-bootstrap';

/**
 * Element de liste qui contiendra une (ou une combinaison) annexe
 */
let AnnexTechnologyElement: FunctionComponent = ({children}) => (
    <ListGroup.Item>
        {children}
    </ListGroup.Item>
);

export default Object.assign(AnnexTechnologyElement, {
    Technology: AnnexTechnology
});