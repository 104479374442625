import React, {FunctionComponent, useState} from 'react';
import {Button} from 'react-bootstrap';
import ExperienceDisplayModal from '../displays/experience-display-modal';
import {ExperienceModalInterface} from '../../../../../data-structures/components/experience-modal.interface';

type Props = {
    ExperienceModal: ExperienceModalInterface
    size?: 'sm' | 'lg' | 'xl';
    /**
     * Only for tracking
     */
    technologyName: string
}

const ExperienceModalButton: FunctionComponent<Props> = ({ExperienceModal, size, technologyName}) => {
    const [show, setShow] = useState(false);

    // const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <div className="d-grid gap-2">
            <Button variant="success" onClick={handleShow}
                    data-track-component="ExperienceModalButton"
                    data-track-experience={ExperienceModal.name}
                    data-track-technology={technologyName}
            >
                {ExperienceModal.name}
            </Button>
            <ExperienceDisplayModal show={show} setShow={setShow} size={size} ExperienceModal={ExperienceModal}/>
        </div>
    );
};

export default ExperienceModalButton;