import React from 'react';
import TechCard from '../../../../components/technologies-page/tech-card';
import {TechnologyCardType} from '../../../../data-structures/components/technology-card.type';
import ApprentissagePaloItModal from '../experience-modals/apprentisage-palo-it-modal';

const DaggerIoTechnology: TechnologyCardType = ({cardSize = 'large'}) => {
    const technologyName = 'Dagger.io';

    return (
        <TechCard className="border-warning"
                  imageSource="img/technologies/dagger-io_logo.svg"
                  technologyName={technologyName}
                  imageWidthInPercent={40}
                  noMarginBottom={true}>

            <TechCard.Annexes>
                <small className="mb-0">Les technologies et outils :</small>
                <TechCard.Annexes.Element>
                    <TechCard.Annexes.Element.Technology
                        technologyParentName={technologyName}
                        technologyName="TypeScript">
                        Sur-ensemble syntaxique de JavaScript améliorant la structure et la sécurité du code.
                    </TechCard.Annexes.Element.Technology>
                </TechCard.Annexes.Element>
                <TechCard.Annexes.Element>
                    <TechCard.Annexes.Element.Technology
                        technologyParentName={technologyName}
                        technologyName="Gitlab CI">
                        Plateforme d’intégration continue proposée par Gitlab.
                    </TechCard.Annexes.Element.Technology>
                </TechCard.Annexes.Element>
            </TechCard.Annexes>

            <TechCard.Gain>
                Dagger.io me permet d’automatiser des tâches de projet comme les constructions de livrable ou de déploiement de livrable.
                Ses automatisations sont portables à travers les plateformes de CI/CD.
            </TechCard.Gain>

            <TechCard.AddInfo technologyName={technologyName}>
                <TechCard.AddInfo.Affinity
                    technologyName={technologyName}
                    gradeValue={4.5}>
                    J'apprécie la portabilité apportées par cette technologie et l'utilisation de langage que je maîtrise
                    pour la programmation des automatisations.
                </TechCard.AddInfo.Affinity>
                <TechCard.AddInfo.Experience
                    technologyName={technologyName}
                    gradeValue={2}>
                    Je nécessite plus d'expérience pour être plus performant et à l'aise, mais je l’utilise le plus
                    possible dans mes projets.
                </TechCard.AddInfo.Experience>
            </TechCard.AddInfo>
            <TechCard.Experiences.Group>
                <TechCard.Experiences.Group.Element>
                    <TechCard.Experiences.Group.Element.Button.Modal
                        technologyName={technologyName}
                        ExperienceModal={ApprentissagePaloItModal} size='lg'/>
                </TechCard.Experiences.Group.Element>
            </TechCard.Experiences.Group>
        </TechCard>
    );
};

export default DaggerIoTechnology;