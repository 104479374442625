import React, {FunctionComponent} from 'react';
import EventCard from '../../../components/events-page/event-card';
import Alinea from '../../../components/alinea';

const AtelierAgileEskerEvent: FunctionComponent = () => (
    <EventCard name="Atelier méthode Agile - par les Scrum Masters d'ESKER" date="14 novembre 2018" place="42 Lyon">
        <EventCard.Description>
            <Alinea/>Atelier d'introduction à la méthode agile avec des Scrum masters d'ESKER. Nous avons profité de
            leurs expertises et l’on s’est exercé à appliquer cette méthodologie de manière ludique.
        </EventCard.Description>
    </EventCard>
);

export default AtelierAgileEskerEvent;