import {FunctionComponent} from 'react';

const TechnologyPartHeader: FunctionComponent = () => (
    <div className="mt-4 mb-3">
        <div className="row justify-content-center">
            <h1 className="text-center mb-0">Les technologies que je connais</h1>
        </div>
        <div className="row justify-content-center mt-0">
            <p className="text-secondary text-center">
                Notez que mes autoévaluations ne sont pas forcément exacte
            </p>
        </div>
    </div>
);

export default TechnologyPartHeader;