import React, {FunctionComponent} from 'react';
import {ExperienceModalInterface} from '../../../../data-structures/components/experience-modal.interface';
import Alinea from '../../../../components/alinea';

const MdmRemixModalTitle: FunctionComponent = () => (
    <span>MDM Remix</span>
);

const MdmRemixModalContext: FunctionComponent = () => (
    <p>
        <Alinea/> Un "MDM remix" le 12 et 13 mars 2019 organisé par Erasme.
        Il s'agit d'aider les Maisons de la Métropole à trouver les solutions d'assistance sociale de demain. <br/>
        <Alinea/>Après ces deux jours, les idées qui ont émergé avait la possibilité d'être prototypées pendant deux
        semaines de workshop qui ont suivie.<br/>
        Le projet était d'améliorer l'accueil des MDMs sur deux axes :
        <ul>
            <li> Des équipements modulables pour les MDMs</li>
            <li> Une application pour que les agents d'accueils puissent les rendre mobiles</li>
        </ul>
        <Alinea/><span className="fw-bold">J'ai développé cette application durant le workshop.</span>
    </p>
);

const MdmRemixModalGoal: FunctionComponent = () => (
    <p>
        <Alinea/>L'application a pour objectif d'éviter à des usagers d'attendre dans la file d'attente pour des
        interactions rapides et de désengorger l'accueil dans les Maisons de la Métropole.


        <Alinea/> Features prévus :
        <ul>
            <li>Prise de rendez-vous</li>
            <li>Signaler l'arrivée d'un usager pour son rendez-vous</li>
            <li>Signaler l'arrivée d'un usager pour un AIS</li>
            <li>Signaler le dépôt de document pour un usager</li>
            <li>Connaître les documents qu'un usager peut retirer</li>
            <li>Retirer un document d'un usager</li>
            <li>Rediriger un usager qui aura besoin d'un service qui n'est pas proposé par la MDM</li>
        </ul>

        <div className="border rounded ps-1"><span className="fw-light">
            Pour le signalement de l'arrivé : l'agent recevra une notification de l'arrivée de l'usager sur son poste de
            travail.</span><br/>
            <span className="fw-light">
            Pour le dépôt de document : envoyer une SMS à l'usager qui a un document à récupérer.</span><br/>
            <span className="fw-light">
            Pour la redirection : le projet "Boussole" sera utilisé pour rediriger l'usager.</span></div>
    </p>
);

const MdmRemixModalResult: FunctionComponent = () => (
    <p>
        Un <span className="fw-bold">script bash d'installation</span> pour l'application a été produit
        et mis sur un <a href="https://gitlab.com/charles.garoux/installeur" target="_blank" rel="noopener noreferrer">dépôt publique
        Gitlab</a>.<br/>

        Une <span className="fw-bold">application web</span> avec le framework PHP Laravel. L'application n'a
        pas pu être terminé à temps.<br/>
        <br/>
        <span className="fw-bold">Features intégrés :</span>
        <ul>
            <li>
                L'authentification de l'agent qui l'utilise
            </li>
            <li>
                Prise des rendez-vous avec une agent référent et pour un AIS <br/>
                <span className="fw-light border rounded">L'AIS est prevenu comme un rendez-vous</span>
            </li>
            <li>
                Signalement de l'arrivé d'un usager pour une rendez-vous<br/>
                <span className="fw-light border rounded">Le système de notification n'a pas pu être fait à temps</span>
            </li>
            <li>
                Signaler le dépôt de document pour un usager<br/>
                <span className="fw-light border rounded">Le système d'envoie de SMS n'a pas pu être fait à temps</span>
            </li>
            <li>Connaître les documents qu'un usager peu retirer</li>
            <li>Retirer un document d'un usager</li>
            <li>Ajout d'agent, d'usager, de rendez-vous et de MDM pour faire les démonstrations<br/>
                <span className="fw-light border rounded">Cette partie la n'est là que pour faire la démonstration de l'application.
                    Un agent connecté peut y accéder.</span>
            </li>
        </ul>
    </p>
);

const MdmRemixModal: ExperienceModalInterface = {
    name: 'MDM Remix',
    Title: MdmRemixModalTitle,
    Context: MdmRemixModalContext,
    Result: MdmRemixModalResult,
    Goal: MdmRemixModalGoal,

    info: {enjoyingNote: 5, learningNote: 4},
    git: {
        platform: 'Gitlab',
        link: 'https://gitlab.com/charles.garoux/application-mdm-remix',
        public: true
    },
    technologiesUsed: ['HTML', 'CSS', 'JavaScript', 'Bootstrap', 'PHP', 'Laravel', 'SQL', 'MariaDB', 'Bash']
};

export default MdmRemixModal;