import React, {FunctionComponent} from 'react';
import {ExperienceModalInterface} from '../../../../data-structures/components/experience-modal.interface';
import Alinea from '../../../../components/alinea';

const GestHebergModalTitle: FunctionComponent = () => (
    <span>gestHeberg</span>
);

const GestHebergModalContext: FunctionComponent = () => (
    <p>
        <Alinea/> Ce projet était un TP en BTS SIO qui demandais aux étudiants des deux option (SLAM et SISR) de
        travailler sur le développement d'une application web de gestion.
    </p>
);

const GestHebergModalGoal: FunctionComponent = () => (
    <p>
        <Alinea/> Développer une application Web de gestion pour que l'étudiant en SISR puisse gérer des contrats de
        clients.
    </p>
);

const GestHebergModalResult: FunctionComponent = () => (
    <p>
        <Alinea/> L'application a été développé avec
        les frameworks Laravel et Boostrap mais le TP a été arrêté rapidement par les
        professeurs par manque de temps. De nombreux groupes n'ont pas peu terminer le
        projet.
    </p>
);

const GestHebergModal: ExperienceModalInterface = {
    name: 'gestHeberg',
    Title: GestHebergModalTitle,
    Context: GestHebergModalContext,
    Result: GestHebergModalResult,
    Goal: GestHebergModalGoal,

    info: {enjoyingNote: 1.5, learningNote: 3},
    git: {
        platform: 'Gitlab',
        link: 'https://gitlab.com/charles.garoux/gestHeberg',
        public: true
    },
    technologiesUsed: ['HTML', 'CSS', 'Bootstrap', 'PHP', 'Laravel', 'MariaDB']
};

export default GestHebergModal;