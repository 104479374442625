import React from 'react';
import TechCard from '../../../../components/technologies-page/tech-card';
import {TechnologyCardType} from '../../../../data-structures/components/technology-card.type';
import InternshipMillionVictories from '../experience-links/internship-million-victories';

const LensTechnology: TechnologyCardType = ({cardSize = 'large'}) => {
    const technologyName = 'Lens';

    return (
        <TechCard className="border-warning"
                  imageSource="img/technologies/lens_logo.svg"
                  technologyName={technologyName}
                  imageWidthInPercent={40}
                  cardSize={cardSize}>

            <TechCard.Gain>
                Lens me permet d'observer et manipuler des clusters Kubernetes ainsi que leurs configurations.
            </TechCard.Gain>

            <TechCard.AddInfo technologyName={technologyName}>
                <TechCard.AddInfo.Affinity
                    technologyName={technologyName}
                    gradeValue={4}>
                    J'apprécie le confort apporté par la visualisation de l'état des clusters et de pouvoir travailler
                    directement sur le cluster à travers l'outil.
                </TechCard.AddInfo.Affinity>
                <TechCard.AddInfo.Experience
                    technologyName={technologyName}
                    gradeValue={1.5}>
                    Je nécessite de beaucoup plus d'expérience et des cas d'utilisation spéciaux pour connaître plus en
                    profondeur les différentes capacités de Lens, mais je l’utilise quand j’ai à manipuler un cluster
                    Kubernetes.
                </TechCard.AddInfo.Experience>
            </TechCard.AddInfo>

            <TechCard.Experiences.Group>
                <TechCard.Experiences.Group.Element>
                    <TechCard.Experiences.Group.Element.Button.Link
                        technologyName={technologyName}
                        experienceLinkData={InternshipMillionVictories}/>
                </TechCard.Experiences.Group.Element>
            </TechCard.Experiences.Group>
        </TechCard>
    );
};

export default LensTechnology;