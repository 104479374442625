import React from 'react';
import TechCard from '../../../../components/technologies-page/tech-card';
import {TechnologyCardType} from '../../../../data-structures/components/technology-card.type';

const GitTechnology: TechnologyCardType = ({cardSize = 'large'}) => {
    const technologyName = 'Git';

    return (
        <TechCard className="border-warning"
                  imageSource="img/technologies/git_logo.svg"
                  technologyName={technologyName}
                  imageWidthInPercent={40}
                  cardSize={cardSize}>

            <TechCard.Annexes>
                <small className="mb-0">Les outils :</small>
                <TechCard.Annexes.Element>
                    <TechCard.Annexes.Element.Technology
                        technologyParentName={technologyName}
                        technologyName="GitKraken">
                        Logiciel d’interface graphique de client Git.
                    </TechCard.Annexes.Element.Technology>
                </TechCard.Annexes.Element>
                <TechCard.Annexes.Element>
                    <TechCard.Annexes.Element.Technology
                        technologyParentName={technologyName}
                        technologyName="Gitlab">
                        Logiciel libre de forge logiciel basé sur git.
                    </TechCard.Annexes.Element.Technology>
                </TechCard.Annexes.Element>
            </TechCard.Annexes>

            <TechCard.Gain>
                Git me permet d’historiser le développement de mes projets, d'organiser le développement et le travail
                d'équipes.
            </TechCard.Gain>

            <TechCard.AddInfo technologyName={technologyName}>
                <TechCard.AddInfo.Affinity
                    technologyName={technologyName}
                    gradeValue={4}>
                    J'apprécie les avantages apportés comme par exemple l’historisation ou le fait de pouvoir travailler
                    sur différentes branches avant de les assembler.
                </TechCard.AddInfo.Affinity>
                <TechCard.AddInfo.Experience
                    technologyName={technologyName}
                    gradeValue={3}>
                    Je nécessite de plus d'expérience et des cas d'utilisation spéciaux pour connaître plus en
                    profondeur les différentes capacités de Git, mais je l’utilise constamment dans mon travail.
                </TechCard.AddInfo.Experience>
            </TechCard.AddInfo>
        </TechCard>
    );
};

export default GitTechnology;