import React, {FunctionComponent} from 'react';

type Props = {
    name: string
    link: string
}

const EventCardLink: FunctionComponent<Props> = ({name, link}) => {
    return (
        <div className="d-grid gap-2 mt-3">
            <a className="btn btn-primary" href={link} target="_blank" rel="noopener noreferrer" role="button">
                {name}
            </a>
        </div>
    );
};


export default EventCardLink;