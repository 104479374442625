import React, {FunctionComponent} from 'react';
import EventCard from '../../../components/events-page/event-card';
import Alinea from '../../../components/alinea';

const WorkshopMdmRemixEvent: FunctionComponent = () => (
    <EventCard name="Workshop MDM Remix" date="15 - 29 mars 2019">
        <EventCard.Description>
            <Alinea/>Ce workshop a pour but de développer en équipe des prototypes de solutions trouvées pendant le MDM
            Remix.
        </EventCard.Description>
        <EventCard.Annexe.Link
            name="L’article sur l'événement"
            link="https://www.erasme.org/MDM-Remix"/>
        <EventCard.Annexe.Link
            name="Solution sur laquelle mon équipe travaillé"
            link="https://www.erasme.org/Auto-Post"/>
    </EventCard>
);

export default WorkshopMdmRemixEvent;