import React, {FunctionComponent} from 'react';
import EventCard from '../../../components/events-page/event-card';
import Alinea from '../../../components/alinea';

const ShadowComexEvent: FunctionComponent = () => (
    <EventCard name="Shadow Comex - Entreprise du futur 2019" date="17 janvier 2019" place="Centre de congrès de Lyon">

        <EventCard.Description>
            <Alinea/>Un "Shadow Comex" est un comité exécutif formé exclusivement de jeunes. L'entreprise de notre
            groupe était <a href="https://www.dimotrans.com/" target="_blank" rel="noopener noreferrer">DIMOTRANS Group</a>, une ETI du secteur du
            transport international et de la logistique
        </EventCard.Description>

        <EventCard.Annexe.Modal name="Description" size="lg">
            <p>
                Un "Shadow Comex" est un comité exécutif formé exclusivement de jeunes. Nous étions 70
                étudiant-e-s de l'emlyon business school, l'École catholique d'arts et métiers (ECAM) et
                de l'école 42 Lyon.<br/>
                Une entreprise nous énonçais la problématique stratégique et nous avions jusqu’au soir
                pour trouver des solutions.
            </p>
            <p className="mb-0"><span className="font-weight-bold">Notre groupe :</span>
                <ul>
                    <li>3 de l'emlyon</li>
                    <li>3 de l'école 42 Lyon</li>
                    <li>1 de l'ECAM</li>
                </ul>
            </p>
            <p className="mb-0">
                <span className="font-weight-bold">Notre entreprise :</span> <a
                href="https://www.dimotrans.com/" target="blank">DIMOTRANS Group</a> est une ETI du
                secteur du transport international et de la logistique
                <ul>
                    <li>Chiffre d'affaire : 303 M€ en 2017</li>
                    <li>Effectifs : 1450 collaborateurs</li>
                    <li>50 agences en France et une dizaine de filiales à l’étranger</li>
                    <li>Clients : tous types d’industrie</li>
                </ul>
            </p>
            <p className="mb-0">
                Le sujet était un sujet sur les ressource humaines :<br/>
                <span className="font-weight-bold ml-4 mp-0">Comment l’entreprise doit-elle se transformer pour répondre aux attentes des futures
              générations de collaborateurs ?</span><br/>
                <ul className="mp-0">
                    <li>le management (le management idéal ET le manager idéal)</li>
                    <li>l'environnement de travail (conditions de travail idéales)</li>
                    <li>les valeurs de l'entreprise</li>
                </ul>
            </p>
            <p>Après avoir présenté toutes nos idées on nous a demandé si nous voudrions présenter nos
                idées au comité exécutif de l'entreprise.</p>
        </EventCard.Annexe.Modal>

        <EventCard.Annexe.Modal name="Ensemble des idées générées" size="lg">
            <h3>Management :</h3>
            <ul>
                <li>Évaluation 360° du travail : des collaborateurs par eux, de la direction par les
                    collaborateurs et sur les résultats.
                </li>
                <li>Objectifs d’une semaine : organisation du travail avec des étapes hebdomadaires à
                    atteindre pour permettre
                    une flexibilité du travail.
                </li>
                <li>Communication du COMEX sur les problématiques et décisions prises par et pour
                    l’entreprise.
                </li>
                <li>Temps d’échange avec la direction : pause café commune, espace sans hiérarchie.</li>
            </ul>
            <hr/>
            <h3>Environnement de travail :</h3>
            <ul>
                <li>Construire un environnement de travail modulable fait par et pour les
                    collaborateurs.
                </li>
                <ul>
                    <li>Permettre la personnalisation de son espace de travail (mobilier amovible).</li>
                    <li>Murs de positivité avec réussites du jour, bonnes nouvelles, célébrations des
                        initiatives et prises de risques.
                    </li>
                    <li>Fournir des locaux conviviaux, agréables et propices à la productivité (lois des
                        couleurs et influences des couleurs sur le cerveau humain, plantes vertes, ...).
                    </li>
                    <li>Créer des espaces collaboratifs et prédisposés aux échanges et à la
                        communication.
                    </li>
                </ul>
                <li>Apporter des prestations pour le confort du collaborateur.</li>
                <ul>
                    <li>Fournir des espaces annexes au travail (salle de vie, de repos, d’activités
                        diverses, de jeux, de sport, vestiaires, …).
                    </li>
                    <li>Fournir des services annexes (crèches, conciergerie, …) et intervenants
                        extra-pro mensuels (massage, posturologue, kinésithérapeute, psychologue, …).
                    </li>
                </ul>
            </ul>
            <hr/>
            <h3>Créativité :</h3>
            <ul>
                <li>Temps alloué pour la réalisation de projets/idées personnel-le-s d’amélioration de
                    l’entreprise (par
                    exemple, une demi-journée par semaine pour chaque collaborateur s’il le souhaite).
                </li>
                <li>Quelques heures par semaine d’investissement dans un organisme avec un impact social
                    en dehors de
                    l’entreprise (hôpital, prison, association…).
                </li>
                <li>Mini-incubateur au sein de l’entreprise, ouvert à tous et à tout moment de la
                    journée.
                </li>
            </ul>
            <hr/>
            <h3>Formations internes :</h3>
            <ul>
                <li>Formation de sensibilisation des travailleurs au développement durable et à des
                    gestes simples au quotidien.
                </li>
                <li>Journées d’immersion dans des services ou unités différent-e-s pour se familiariser
                    avec d’autres aspects de l’entreprise.
                </li>
                <li>Mettre en place des formations de type “peer learning” sur des outils, logiciels
                    afin de partager les connaissances, réduire les coûts (pas besoin d’intervenants
                    extérieurs) et responsabiliser ceux qui se portent volontaire pour apprendre aux
                    autres.
                </li>
                <li>Formation des managers pour passer d’un management par soumission à un management
                    qui “facilite” les rapports entre travailleurs et replace les hommes au cœur de
                    l’entreprise.
                </li>
                <li>Partager les “bonnes pratiques”, trucs et astuces que les travailleurs peuvent avoir
                    découvert afin d’en faire profiter l’ensemble de l’entreprise.
                </li>
                <li>Vigilance partagée sur des problématiques de sécurité.</li>
            </ul>
            <hr/>
            <h3>Communauté :</h3>
            <ul>
                <li>Réseau social d’entreprise.</li>
                <ul>
                    <li>Chat room par thèmes (sport, sorties…).</li>
                    <li>Mise en place du covoiturage entre collègues.</li>
                </ul>
                <li>Organisation d’activités entre collègues réalisée par un responsable au sein
                    Dimotrans ou une organisme extérieure (exemple : Linkoon). Pourra prendre la forme
                    de jeux, sorties, événements sportifs, activités de création (théâtre
                    d’improvisation, dessin ou autre), soirées, repas... Pour tous les collaborateurs
                    (direction inclue).
                </li>
                <li>Négociation d’avantages / réductions avec les magasins, entreprises et restaurants
                    fréquentés par les employés.
                </li>
            </ul>
            <hr/>
            <h3>Recrutement :</h3>
            <ul>
                <li>Dans la communication externe (surtout dans les forums de recrutement ou autre),
                    mettre en avant les efforts de l’entreprise sur des problématiques environnementales
                    et durables.
                </li>
                <li>Mettre en place des tests de flexibilité/adaptabilité/agilité par des mises en
                    situations inattendues lors des sessions de recrutement pour déceler les
                    profils/talents qui sauront réagir face à l’adversité et les changements rapides qui
                    touchent et vont toucher l’entreprise.
                </li>
            </ul>
        </EventCard.Annexe.Modal>

    </EventCard>
);

export default ShadowComexEvent;