import {FunctionComponent} from 'react';
import {ExperienceUdemyInterface} from '../../../../../data-structures/components/experience-udemy-modal.interface';
import Alinea from '../../../../../components/alinea';

const PracticalBeginnersCourseToServerlessApplicationsUdemyPresentation: FunctionComponent = () => (
    <div>
        <p className="mt-3">
            <Alinea/>Cette formation aborde les bases du <a href="https://serverless.com/" target="_blank" rel="noopener noreferrer">Framework
            serverless</a> avec AWS.<br/> <br/>
            <Alinea/>L’on y développe et déploie de petits projets grâce à la capacité d’IaC (Infrastructure as Code) du
            Framework.<br/> <Alinea/>Au cours de la formation et des exercices, les projets comportent l’utilisation
            d'éléments et de service AWS : </p>
        <ul>
            <li>Lambda</li>
            <li>Rôles IAM</li>
            <li>API Gateway</li>
            <li>Tables DynamoDB</li>
        </ul>
    </div>
);

const PracticalBeginnersCourseToServerlessApplicationsUdemy: ExperienceUdemyInterface = {
    name: 'Practical beginners course to Serverless Applications',
    certificatLink: 'https://www.udemy.com/certificate/UC-50168f33-fe34-487f-bf90-c0dbaba88fd4/',
    Presentation: PracticalBeginnersCourseToServerlessApplicationsUdemyPresentation
};

export default PracticalBeginnersCourseToServerlessApplicationsUdemy;