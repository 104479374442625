import {FunctionComponent} from 'react';
import {ExperienceUdemyInterface} from '../../../../../data-structures/components/experience-udemy-modal.interface';
import Alinea from '../../../../../components/alinea';

const AwsEssentialsUdemyPresentation: FunctionComponent = () => (
    <div>
        <p className="mt-3">
            <Alinea/>Cette formation aborde les bases de la création
            d'infrastructure sur AWS.<br/>
            <br/>
            <Alinea/><b>Tout au long on crée une infrastructure complète
            dans
            le
            Cloud AWS</b> du projet "Omega 2.0". <a
            href="https://interactive.linuxacademy.com/diagrams/ProjectOmega2.html"
            target="_blank" rel="noopener noreferrer">Le support pédagogique de la formation est interactif</a>.
            Il
            contient l’infrastructure créer et les informations pédagogiques sur chacun des
            éléments.<br/>
            <br/>
            Les différents éléments et services abordés pour le projet :
        </p>
        <ul>
            <li>Le compte root AWS</li>
            <li>Le service AIM pour créer une équipe de développeurs</li>
            <li>Le réseau avec la mise en place :</li>
            <ul>
                <li>D’un VPC</li>
                <li>De sous réseaux</li>
                <li>De table de routage (RT)</li>
                <li>De liste d'accès au réseau (NACL)</li>
                <li>D'accès à internet (Internet Gateway)</li>
            </ul>
            <li>Le service de calcul EC2 avec :</li>
            <ul>
                <li>La création d’une instance</li>
                <li>La mise en place de groupes de sécurités</li>
            </ul>
            <li>Le service de stockage S3 avec :</li>
            <ul>
                <li>La création d’un bucket</li>
                <li>La revue des différentes classes de stockage</li>
                <li>La mise en place d’un cycle de vie</li>
                <li>La mise en place de droits</li>
                <li>La mise en place de versionning</li>
            </ul>
            <li>Les serveurs de base de données avec :</li>
            <ul>
                <li>La mise en place d’un RDS dans un sous réseau privé</li>
                <li>L'approvisionnement du RDS par tunnel SSH</li>
            </ul>
            <li>La surveillance, les alarmes et les notifications avec :</li>
            <ul>
                <li>Une mise en place d’alarmes avec CloudWatch</li>
                <li>L'envoie de notification d’alarme avec SNS par email et SMS</li>
            </ul>
            <li>L'équilibrage de charge et de mise à l'échelle automatique avec :</li>
            <ul>
                <li>Une mise en place d’un ELB</li>
                <li>Une mise en place d’une mise à l'échelle automatique</li>
            </ul>
            <li>Le système de nom de domaines Route 53</li>
        </ul>
    </div>
);

const AwsEssentialsUdemy: ExperienceUdemyInterface = {
    name: 'AWS Essentials (2019)',
    certificatLink: 'https://www.udemy.com/certificate/UC-d0f02b51-4816-4696-bcf5-003159ed24b7/',
    Presentation: AwsEssentialsUdemyPresentation
};

export default AwsEssentialsUdemy;