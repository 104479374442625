import React, {FunctionComponent} from 'react';
import LearningDayAwsEvent from './event-cards/learning-day-aws-event';
import GamedayAws42LyonEvent from './event-cards/gameday-aws-42-lyon';
import GamedayAwsEvent from './event-cards/gameday-aws';
import HourOfCode2019Event from './event-cards/hour-of-code-2019';
import HourOfCode2018Event from './event-cards/hour-of-code-2018';
import WorkshopMdmRemixEvent from './event-cards/workshop-mdm-remix';
import MdmRemixEvent from './event-cards/mdm-remix';
import ShadowComexEvent from './event-cards/shadow-comex';
import AtelierAgileEskerEvent from './event-cards/atelier-agile-esker';

const EventsList: FunctionComponent = () => (
    <div>
        <div className="row justify-content-center">
            <h3 className="mt-4 text-center">Événements auxquels j'ai participé</h3>
        </div>
        <div className="row justify-content-center mt-0 mb-0">
            <span className="text-info text-center">
                Les événements, conférences et meetups auxquels je participe et assiste ne sont pas tous listés
            </span>
        </div>

        <div className="row mb-3">
            <LearningDayAwsEvent/>
            <GamedayAws42LyonEvent/>
            <GamedayAwsEvent/>
            <HourOfCode2019Event/>
            <WorkshopMdmRemixEvent/>
            <MdmRemixEvent/>
            <ShadowComexEvent/>
            <HourOfCode2018Event/>
            <AtelierAgileEskerEvent/>
        </div>
    </div>
);

export default EventsList;