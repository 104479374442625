import React, {FunctionComponent} from 'react';

const TechnologicalNews: FunctionComponent = () => (
    <div>
        <div className="row justify-content-center">
            <h3 className="mt-4 text-center">Mon actualité technologique</h3>
        </div>
        <div className="row justify-content-center mt-3">
            <div className="col-xxl-6 col-lg-9 col-md-9 col-12">
                <div className="border rounded-3 shadow py-3 px-3">
                    <span className="h5 font-weight-bold">Culture professionnel</span>
                    <ul className="mb-0">
                        <li className="mt-1">
                            En formation à l'école <a href="https://www.42lyon.fr/" target="blank">42 Lyon</a> sur des
                            projets <b>Web</b>, <b>Cloud</b> et <b>DevOps</b>.
                        </li>
                        <li className="mt-1">
                            J’ai récemment mis en ligne un <a
                            href="https://gitlab.com/charles.garoux/test-technique-palo-it"
                            target="blank">projet de démonstration technique</a> pour ma candidature à l’entreprise où je suis actuellement en alternance.
                        </li>
                        <li className="mt-1">
                            Je suis actuellement en création d'une branche de projet pédagogique pour le cursus de <a href="https://www.42lyon.fr/" target="blank">42 Lyon</a> sur le domaine du Cloud et DevOps par l'Infrastructure as Code.
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
);

export default TechnologicalNews;