import React from 'react';
import TechCard from '../../../../components/technologies-page/tech-card';
import {TechnologyCardType} from '../../../../data-structures/components/technology-card.type';

const GitKrakenTechnology: TechnologyCardType = ({cardSize = 'large'}) => {
    const technologyName = 'GitKraken';

    return (
        <TechCard className="border-warning"
                  imageSource="img/technologies/gitkraken_logo.svg"
                  technologyName={technologyName}
                  imageWidthInPercent={40}
                  cardSize={cardSize}>

            <TechCard.Gain>
                GitKraken met permet de travailler plus facilement avec Git et de mieux visualiser l'état du dépôt Git
                et des modifications apportées au code.
            </TechCard.Gain>

            <TechCard.AddInfo technologyName={technologyName}>
                <TechCard.AddInfo.Affinity
                    technologyName={technologyName}
                    gradeValue={4}>
                    J'apprécie le confort apporté par la visualisation de l'état du dépôt, des modifications et la
                    gestion des conflits lors de merge.
                </TechCard.AddInfo.Affinity>
                <TechCard.AddInfo.Experience
                    technologyName={technologyName}
                    gradeValue={3}>
                    Je nécessite de plus d'expérience et des cas d'utilisation spéciaux pour connaître plus en
                    profondeur les différentes capacités de GitKraken, mais je l’utilise constamment dans mon travail.
                </TechCard.AddInfo.Experience>
            </TechCard.AddInfo>
        </TechCard>
    );
};

export default GitKrakenTechnology;